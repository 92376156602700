import type { FC, ReactNode } from 'react';
import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material/';
import { Button, Collapse, ListItem } from '@mui/material';
import clsx from 'clsx';

import useStyles from './NavItem.styles';

interface NavItemProps {
  children?: ReactNode;
  className?: string;
  depth: number;
  href?: string;
  icon?: any;
  info?: any;
  open?: boolean;
  title: string;
}

const NavItem: FC<NavItemProps> = ({
  children,
  className,
  depth,
  href = '',
  icon: Icon,
  info: Info,
  open: openProp = false,
  title,
  ...props
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(openProp);

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...props}
      >
        <Button className={classes.button} onClick={handleToggle} style={style}>
          {Icon && <Icon className={classes.icon} size="20" />}

          <span className={classes.title}>{title}</span>

          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>

        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...props}
    >
      <Button
        className={clsx(classes.buttonLeaf, classes.active, `depth-${depth}`)}
        component={RouterLink}
        style={style}
        to={href}
      >
        {Icon && <Icon className={classes.icon} size="20" />}

        <span className={classes.title}>{title}</span>

        {Info && <Info />}
      </Button>
    </ListItem>
  );
};

export default NavItem;
