import * as api from './api';
import axiosInstance from './axios';
import axiosMock from './axiosMock';

export type {
  GetDutByIdProps,
  GetDutByTrackingCodeProps,
  GetDutProps,
  GetDutsProps,
  GetEventCategoriesProps,
  GetInspectionProps,
  GetInspectionsProps,
  GetInspectionTypesProps,
  GetLocationsProps,
  PaginatedRequestProps,
  PaginatedResponse,
  PostEventProps,
  PostInspectionFormProps,
  RequestProps,
} from './api';

export { axiosInstance, axiosMock };

export default api;
