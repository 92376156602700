import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfirm } from 'material-ui-confirm';
import { CenterFocusWeak as ScanIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { CircularProgress, Container, Fab, Grid, Paper } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';

import { MAX_INSPECTIONS_PENDING } from 'src/constants';
import { TestIDs } from 'src/testIDs';
import type { Inspection } from 'src/types';
import useInspections from 'src/hooks/useInspections';
import {
  useInspectionsRead,
  useInspectionTypesRead,
} from 'src/services/state/server/Inspection';
import { useLocationsRead } from 'src/services/state/server/Location';
import InspectionCard from 'src/components/InspectionCard/InspectionCard';
import MissingContentBox from 'src/components/MissingContentBox/MissingContentBox';
import Page from 'src/components/Page/Page';
import QrScannerDialog from 'src/components/QrScannerDialog/QrScannerDialog';

import Header from './Header/Header';
import InspectionsFilter from './InspectionsFilter/InspectionsFilter';

import useStyles from './InspectionsView.styles';

const inspectionsTestIDs = TestIDs.views.inspection.inspectionsView;

const InspectionsView: FC = () => {
  const { t } = useTranslation();
  const { state: inspState } = useInspections();
  const { isFetching: isFetchingLocations } = useLocationsRead();
  const { isFetching: isFetchingInspTypes } = useInspectionTypesRead();
  const [qrScannerDialogOpen, setQrScannerDialogOpen] = useState(false);
  const [selectedInspection, setSelectedInspection] = useState<Inspection>();
  const classes = useStyles();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  useEffect(() => {
    const entries: PerformanceNavigationTiming[] = performance.getEntriesByType(
      'navigation',
    ) as PerformanceNavigationTiming[];
    entries.forEach((entry) => {
      if (entry.type === 'reload') {
        queryClient.clear();
      }
    });
  }, [queryClient]);

  const {
    data: infiniteInspectionsData,
    fetchNextPage: fetchNextInspectionsPage,
    hasNextPage: hasNextInspectionsPage,
    isFetching: isFetchingInspections,
  } = useInspectionsRead({ requestProps: { ...inspState.filters } });

  const inspectionsTotal = infiniteInspectionsData?.pages[0].count;
  const inspectionsPending = inspState.inspectionsPending ?? [];
  const maxInspectionsPendingReached =
    inspectionsPending.length >= MAX_INSPECTIONS_PENDING;
  const inspections = _.flatten(
    infiniteInspectionsData?.pages.map(({ results }) => results),
  );

  const loading =
    isFetchingInspections || isFetchingLocations || isFetchingInspTypes;

  const handleQrScannerDialog = (inspection?: Inspection) => {
    if (inspection && maxInspectionsPendingReached) {
      return confirm({
        cancellationButtonProps: { style: { display: 'none' } },
        description: t('InspectionStartDialog.TooManyDialog.description'),
        title: t('InspectionStartDialog.TooManyDialog.title'),
      });
    }

    setSelectedInspection(inspection);
    setQrScannerDialogOpen(!qrScannerDialogOpen);
  };

  return (
    <Page className={classes.root} title={t('Views.Inspections.title')}>
      <Container maxWidth={false}>
        <Header title={t('Views.Inspections.title')} />

        <Paper className={classes.filterContainer}>
          <InspectionsFilter />
        </Paper>

        <Grid container spacing={3}>
          {loading && !inspections.length && (
            <MissingContentBox content={<CircularProgress />} />
          )}

          {!loading && !inspections.length && (
            <Grid item xs={12}>
              <MissingContentBox
                content={t('Views.Inspections.noInspectionsFound')}
              />
            </Grid>
          )}

          {!!inspections.length && (
            <>
              {inspections.map((inspection) => (
                <Grid key={inspection.id} item xs={12} md={6}>
                  <InspectionCard
                    action={() => handleQrScannerDialog(inspection)}
                    inspection={inspection}
                  />
                </Grid>
              ))}

              <Grid item display="flex" justifyContent="center" xs={12}>
                <LoadingButton
                  disabled={!hasNextInspectionsPage}
                  loading={loading}
                  onClick={() => fetchNextInspectionsPage()}
                  variant="contained"
                >
                  {t('Views.Inspections.loadMoreInspections', {
                    amountCurrent: inspections.length,
                    amountTotal: inspectionsTotal,
                  })}
                </LoadingButton>
              </Grid>
            </>
          )}
        </Grid>
      </Container>

      <Fab
        className={classes.fab}
        color="primary"
        data-test-id={inspectionsTestIDs.fab}
        onClick={() => handleQrScannerDialog()}
        size="medium"
        variant="extended"
      >
        <ScanIcon className={classes.fabIcon} />
        {t('QrScannerDialog.scanQrCode')}
      </Fab>

      {qrScannerDialogOpen && (
        <QrScannerDialog
          inspectionId={selectedInspection?.id}
          matchUuid={selectedInspection?.dut.trackingCode}
          onClose={() => handleQrScannerDialog()}
          open={qrScannerDialogOpen}
        />
      )}
    </Page>
  );
};

export default InspectionsView;
