import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  Link as RouterLink,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import _ from 'lodash';

import routes from 'src/routes';
import { useDutRead } from 'src/services/state/server/Dut';
import { useInspectionsRead } from 'src/services/state/server/Inspection';
import DutInfo from 'src/components/DutInfo/DutInfo';
import InspectionCard from 'src/components/InspectionCard/InspectionCard';
import MissingContentBox from 'src/components/MissingContentBox/MissingContentBox';
import Page from 'src/components/Page/Page';

import Header from './Header/Header';

import useStyles from './DutView.styles';

const DutView: FC = () => {
  const { t } = useTranslation();
  let { dutId } = useParams<{ dutId: string }>();
  dutId = dutId || '';
  const classes = useStyles();
  const navigate = useNavigate();

  const { data: dut, isFetching: isFetchingDut } = useDutRead({
    requestProps: { dutId: parseInt(dutId) },
  });

  const { data: infiniteDutInspections } = useInspectionsRead({
    requestProps: { dutId: parseInt(dutId) },
  });

  const loading = isFetchingDut;
  const isDutChild = !!dut?.rootId;
  const dutRootPath = dut?.rootId
    ? generatePath('/dut' + routes.dut.routes?.index.path || '', {
        dutId: `${dut.rootId}`,
      })
    : '';
  const dutInspections = _.flatten(
    infiniteDutInspections?.pages.map(({ results }) => results),
  );

  /**
   * When selecting an active inspection of the DUT, redirect to the DutInspectionView.
   */
  const handleInspectionSelection = (inspectionId: number) => {
    const redirectPath = generatePath(
      '/dut' + routes.dut.routes?.inspection.path || '',
      {
        dutId: `${dutId}`,
        inspectionId: `${inspectionId}`,
      },
    );

    navigate(redirectPath, { replace: true });
  };

  return (
    <Page className={classes.root} title={t('Views.Dut.title')}>
      <Container maxWidth={false}>
        <Header title={!loading && !!dut ? dut.name : ''} />

        <Grid container spacing={3}>
          {loading && <MissingContentBox content={<CircularProgress />} />}

          {!loading && !dut && (
            <Grid item xs={12}>
              <MissingContentBox content={t('Views.Dut.noDutFound')} />
            </Grid>
          )}

          {!loading && !!dut && (
            <Grid item xs={12}>
              <DutInfo dut={dut} />
            </Grid>
          )}

          {!loading && !!dut && isDutChild && (
            <Grid item xs={12}>
              <MissingContentBox
                content={
                  <Box>
                    <Typography>{t('Views.Dut.isDutChild')}</Typography>

                    <br />

                    <Button
                      color="primary"
                      component={RouterLink}
                      to={dutRootPath}
                      size="large"
                      variant="contained"
                    >
                      {t('Views.Dut.toL1Dut')}
                    </Button>
                  </Box>
                }
              />
            </Grid>
          )}

          {!loading && !!dut && !isDutChild && !dutInspections && (
            <Grid item xs={12}>
              <MissingContentBox content={t('Views.Dut.noInspectionFound')} />
            </Grid>
          )}

          {!loading && !!dut && !isDutChild && !!dutInspections && (
            <>
              {dutInspections?.map((inspection) => (
                <Grid key={inspection.id} item xs={12} md={6}>
                  <InspectionCard
                    action={() => handleInspectionSelection(inspection.id)}
                    emphasizeInsp
                    inspection={inspection}
                  />
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Container>
    </Page>
  );
};

export default DutView;
