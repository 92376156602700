import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},

  buttonWrapper: {
    padding: theme.spacing(2),

    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default useStyles;
