import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  qrReader: {
    width: '100%',
  },

  alert: {
    width: '90%',
    flexDirection: 'column',
    position: 'absolute',
    zIndex: 1,

    [theme.breakpoints.up('sm')]: {
      width: '60%',
    },
  },
}));

export default useStyles;
