import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},

  pictureLabel: {
    position: 'static',
    transform: 'translate(14px) scale(1);',
  },

  gridList: {
    width: '100%',
    flexWrap: 'nowrap',
  },

  tileBar: {
    background: 'transparent',
    height: 'auto',
    padding: theme.spacing(1),
  },

  iconButton: {
    backgroundColor: theme.palette.background.paper + '9',

    '&:hover': {
      backgroundColor: theme.palette.background.paper + 'c',
    },
  },

  buttonWrapper: {
    padding: theme.spacing(2),
  },
}));

export default useStyles;
