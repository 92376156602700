import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import routes from 'src/routes';
import Page from 'src/components/Page/Page';

import useStyles from './NotFoundView.styles';

const NotFoundView: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });
  const { t } = useTranslation();

  return (
    <Page className={classes.root} title={t('Views.NotFound.title')}>
      <Container maxWidth="lg">
        <Typography
          align="center"
          color="textPrimary"
          variant={mobileDevice ? 'h4' : 'h1'}
        >
          {t('Views.NotFound.title')}
        </Typography>

        <Typography align="center" color="textSecondary" variant="subtitle2">
          {t('Views.NotFound.pageNotFound')}
        </Typography>

        <Box justifyContent="center" display="flex" mt={6}>
          <img
            alt={t('Views.NotFound.pageNotFound')}
            className={classes.image}
            src="/static/images/undraw_page_not_found.svg"
          />
        </Box>

        <Box display="flex" justifyContent="center" mt={6}>
          <Button
            color="secondary"
            component={RouterLink}
            to={routes.root.path}
            variant="outlined"
          >
            {t('Views.NotFound.backToStart')}
          </Button>
        </Box>
      </Container>
    </Page>
  );
};

export default NotFoundView;
