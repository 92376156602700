import { colors } from '@mui/material';

import typography from './typography';

const muiOverrides = {
  components: {
    MuiButton: {
      styleOverrides: {
        sizeLarge: {
          padding: '6px 18px',
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        } as const,
      },
    },

    MuiCardHeader: {
      styleOverrides: {
        action: {
          marginTop: 'auto',
        },
      },
    },

    MuiCardActions: {
      styleOverrides: {
        root: {
          marginTop: 'auto',
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0.075)',
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 16,
        },

        spacing: {
          '& > :not(:first-child)': {
            marginLeft: 16,
          },
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingRight: 16,
          paddingLeft: 16,
          display: 'flex',
          flexDirection: 'column',
        } as const,
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingRight: 16,
          paddingLeft: 16,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        fullWidth: {
          // Fixes labels being full width as well which led to invisible clickable areas.
          '& .MuiFormControlLabel-root': {
            paddingRight: 16,
            marginRight: 'auto',
          },
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&:first-letter': {
            textTransform: 'uppercase',
          },

          // Display multiple errors as list items. The TextField error wrapper can't be changed to 'ul'.
          '&.Mui-error > span': {
            marginLeft: 16,
            display: 'list-item',
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600],
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        // Fixes an issue where select labels can overflow the select input.
        formControl: {
          maxWidth: 'calc(100% - 35px)',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },

    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 32,
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          '& .MuiTypography-body1': {
            fontSize: 'inherit', // Fixes different default font-sizes when using the Typography component inside a table cell.
          },
        },

        head: {
          fontWeight: typography.fontWeightBold,
        },
      },
    },
  },
};

export default muiOverrides;
