import type { FC, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import routes from 'src/routes';
import { useUserStore } from 'src/services/auth/auth';
import LoadingScreen from 'src/components/LoadingScreen/LoadingScreen';

interface AuthGuardProps {
  children?: ReactNode;
}

/**
 * Shows nested views only to logged in users and redirects logged out users accordingly.
 */
const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { loggedIn: authLoggedIn, loading } = useUserStore();
  const location = useLocation();
  const offlineLoggedIn =
    !navigator.onLine && localStorage.getItem('isLoggedIn') === 'true';
  const loggedIn = authLoggedIn || offlineLoggedIn;

  if (navigator.onLine) {
    localStorage.setItem('isLoggedIn', loggedIn.toString());
  }

  if (!loggedIn && loading) {
    return <LoadingScreen />;
  }

  if (!loggedIn) {
    return <Navigate to={routes.login.path} state={location} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
