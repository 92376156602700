import type { FC, ReactNode } from 'react';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import routes from 'src/routes';
import { useUserStore } from 'src/services/auth/auth';

interface GuestGuardProps {
  children?: ReactNode;
}

/**
 * Shows nested views only to logged out users and redirects logged in users accordingly.
 */
const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { loggedIn } = useUserStore();
  const location = useLocation();
  const locationState = location.state as any;

  if (loggedIn) {
    if (locationState) {
      // 'from' is only set if the user visited a page of this application but was not logged in.
      // Redirecting to 'from' shows the user the page he initially visited, after a successful login.
      return <Navigate to={locationState.from} />;
    } else {
      // If 'from' is not set, the user is simply redirected to this applications root page.
      return <Navigate to={routes.root.path} />;
    }
  }

  return <>{children}</>;
};

export default GuestGuard;
