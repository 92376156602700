import type { FC, ReactNode } from 'react';
import React from 'react';
import { Helmet } from 'react-helmet';
import type { GridProps } from '@mui/material';
import { Grid } from '@mui/material';
import clsx from 'clsx';

import { PAGE_TITLE } from 'src/constants';
import i18n from 'src/services/i18n/i18n';

import useStyles from './Page.styles';

interface PageProps extends GridProps {
  children?: ReactNode;
  className?: string;
  title?: string;
}

const Page: FC<PageProps> = ({ children, className, title, ...props }) => {
  const classes = useStyles();

  return (
    <Grid container className={clsx(classes.root, className)} {...props}>
      <Helmet>
        <html lang={i18n.language} />
        <title>
          {title} | {PAGE_TITLE}
        </title>
      </Helmet>

      {children}
    </Grid>
  );
};

export default Page;
