export const inspectionKeys = {
  all: ['inspections'] as const,
  detail: (inspectionId?: number) =>
    [...inspectionKeys.details(), inspectionId] as const,
  details: () => [...inspectionKeys.all, 'detail'] as const,
  form: (inspectionFormId: string) =>
    [...inspectionKeys.forms(), inspectionFormId] as const,
  forms: () => [...inspectionKeys.all, 'form'] as const,
  list: (filters: { location?: number; type?: number }) =>
    [...inspectionKeys.all, 'list', filters] as const,
  types: () => [...inspectionKeys.all, 'inspectionTypes'] as const,
};
