import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  cardHeader: {
    width: '100%',
    paddingBottom: theme.spacing(1),
  },

  cardHeaderContent: {
    flex: '0 0 auto',
  },

  cardHeaderAction: {
    marginLeft: theme.spacing(1),
  },

  cardContent: {
    paddingTop: theme.spacing(1),

    display: 'flex',
    flexGrow: 1,

    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },

  typographyContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
  },

  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
