import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},

  buttonWrapper: {
    padding: theme.spacing(2),
  },

  button: {
    minWidth: 0,
  },

  popover: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

export default useStyles;
