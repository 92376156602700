import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { InspectionForm } from 'src/types';
import type { PostInspectionFormProps } from 'src/services/api';
import api from 'src/services/api';
import { useUserStore } from 'src/services/auth/auth';

import { inspectionKeys } from './keys';

export interface UseInspectionFormUpdateProps {}

/**
 * @description Update an inspections form and
 * update the query data on success.
 */
export const useInspectionFormUpdate = () => {
  const queryClient = useQueryClient();
  const { axiosRequestConfig: authConfig } = useUserStore();

  return useMutation(
    (requestProps: Omit<PostInspectionFormProps, 'authConfig'>) => {
      return api.postInspectionForm({
        authConfig,
        ...requestProps,
      } as PostInspectionFormProps);
    },

    {
      onSuccess: (inspectionForm) => {
        const queryKey = inspectionKeys.form(inspectionForm.$id);

        queryClient.setQueryData<InspectionForm>(queryKey, inspectionForm);
      },
    },
  );
};
