import type { ErrorObject } from 'ajv';
import Ajv from 'ajv';
import ajvErrors from 'ajv-errors';
import _ from 'lodash';

import type { InspectionForm, InspectionFormValues } from 'src/types';
import i18n from 'src/services/i18n/i18n';

import messagesLocalizer from './errorMessages';

export interface ValidatorProps {
  removeEmpty?: boolean;
  schema: InspectionForm;
  values: InspectionFormValues;
}

/**
 * Transforms Ajv errors to Formik errors.
 */
export const ajvErrorsToFormikErrors = (
  ajvErrors: ErrorObject[],
): { [formField: string]: string | string[] } => {
  const errorMessages: { [key: string]: string | string[] } = {};

  ajvErrors.forEach((ajvError) => {
    // Create errorMessage for errors that occur within a property and not at the root level.
    if (ajvError.instancePath && ajvError.message) {
      const dataPathSegments = ajvError.instancePath.split('/');
      const fieldName = dataPathSegments[dataPathSegments.length - 1];
      const prevMessages = errorMessages[fieldName] ?? [];

      return (errorMessages[fieldName] = [...prevMessages, ajvError.message]);
    }

    // Create errorMessage for missing fields if no custom "errorMessage" is set in the schema.
    if (ajvError.params.missingProperty && ajvError.message) {
      const fieldName = ajvError.params.missingProperty;
      const prevMessages = errorMessages[fieldName] ?? [];

      return (errorMessages[fieldName] = [...prevMessages, ajvError.message]);
    }

    // Create errorMessage for missing fields if a custom "errorMessage" is set in the schema.
    if (ajvError.keyword === 'errorMessage' && !ajvError.instancePath) {
      ajvError.params.errors.forEach((_ajvError: any) => {
        if (ajvError.message) {
          const fieldName = _ajvError.params.missingProperty;
          const prevMessages = errorMessages[fieldName] ?? [];

          return (errorMessages[fieldName] = [
            ...prevMessages,
            ajvError.message,
          ]);
        }
      });
    }
  });

  return errorMessages;
};

/**
 * Returns a new form data object after removing potentially empty form fields.
 * This will trigger "missingProperty" or "required" error when validating.
 */
export const removeEmptyFields = (values: InspectionFormValues) =>
  Object.fromEntries(
    Object.entries(values).filter(
      ([, fieldValue]) =>
        !_.isEmpty(fieldValue) ||
        typeof fieldValue === 'number' ||
        typeof fieldValue === 'boolean',
    ),
  );

/**
 * Validates form values against the provided JSON-Schema.
 * Error messages are returned in a usable format for Formik.
 */
export const validate = ({
  removeEmpty = true,
  schema,
  values,
}: ValidatorProps) => {
  const ajv = ajvErrors(new Ajv({ allErrors: true, strict: false }));
  const _values = removeEmpty ? removeEmptyFields(values) : values;
  const localizeMessages = messagesLocalizer[i18n.language];

  ajv.validate(schema, _values);

  if (!ajv.errors) return;

  localizeMessages(ajv.errors);

  return ajvErrorsToFormikErrors(ajv.errors);
};
