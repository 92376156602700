import type { ErrorObject } from 'ajv';

/**
 * Copied from https://github.com/ajv-validator/ajv-i18n
 */
export const localize_de = (errors: ErrorObject[] | null) => {
  if (!(errors && errors.length)) return;

  for (let i = 0; i < errors.length; i++) {
    const error = errors[i];
    let cond = undefined;
    let n = undefined;
    let out;

    switch (error.keyword) {
      case '$ref':
        out = 'kann die Referenz ' + error.params.ref + ' nicht auflösen';
        break;
      case 'additionalItems':
        out = '';
        n = error.params.limit;
        out += 'darf nicht mehr als ' + n + ' Element';
        if (n !== 1) {
          out += 'e';
        }
        out += ' enthalten';
        break;
      case 'additionalProperties':
        out = 'darf keine zusätzlichen Felder haben';
        break;
      case 'anyOf':
        out = 'muss einem der Schemata in "anyOf" entsprechen';
        break;
      case 'const':
        out = 'muss gleich der Konstanten sein';
        break;
      case 'contains':
        out = 'muss ein valides Element enthalten';
        break;
      case 'custom':
        out = 'muss die Validierung "' + error.keyword + '" bestehen';
        break;
      case 'dependencies':
        out = '';
        n = error.params.depsCount;
        out += 'muss Feld';
        if (n !== 1) {
          out += 'er';
        }
        out +=
          ' ' +
          error.params.deps +
          ' aufweisen, wenn Feld ' +
          error.params.property +
          ' gesetzt ist';
        break;
      case 'enum':
        out = 'muss einem der vorgegebenen Werte entsprechen';
        break;
      case 'exclusiveMaximum':
        out = '';
        cond = error.params.comparison + ' ' + error.params.limit;
        out += 'muss ' + cond + ' sein';
        break;
      case 'exclusiveMinimum':
        out = '';
        cond = error.params.comparison + ' ' + error.params.limit;
        out += 'muss ' + cond + ' sein';
        break;
      case 'false schema':
        out = 'boolesches Schema ist falsch';
        break;
      case 'format':
        out = 'muss diesem Format entsprechen: "' + error.params.format + '"';
        break;
      case 'formatExclusiveMaximum':
        out = 'formatExclusiveMaximum muss ein boolescher Wert sein';
        break;
      case 'formatExclusiveMinimum':
        out = 'formatExclusiveMinimum muss ein boolescher Wert sein';
        break;
      case 'formatMaximum':
        out = '';
        cond = error.params.comparison + ' ' + error.params.limit;
        out += 'muss ' + cond + ' sein';
        break;
      case 'formatMinimum':
        out = '';
        cond = error.params.comparison + ' ' + error.params.limit;
        out += 'muss ' + cond + ' sein';
        break;
      case 'if':
        out =
          'muss dem Schema "' + error.params.failingKeyword + '" entsprechen';
        break;
      case 'maximum':
        out = '';
        cond = error.params.comparison + ' ' + error.params.limit;
        out += 'muss ' + cond + ' sein';
        break;
      case 'maxItems':
        out = '';
        n = error.params.limit;
        out += 'darf nicht mehr als ' + n + ' Element';
        if (n !== 1) {
          out += 'e';
        }
        out += ' haben';
        break;
      case 'maxLength':
        out = '';
        n = error.params.limit;
        out += 'darf nicht länger als ' + n + ' Zeichen sein';
        break;
      case 'maxProperties':
        out = '';
        n = error.params.limit;
        out += 'darf nicht mehr als ' + n + ' Feld';
        if (n !== 1) {
          out += 'er';
        }
        out += ' haben';
        break;
      case 'minimum':
        out = '';
        cond = error.params.comparison + ' ' + error.params.limit;
        out += 'muss ' + cond + ' sein';
        break;
      case 'minItems':
        out = '';
        n = error.params.limit;
        out += 'darf nicht weniger als ' + n + ' Element';
        if (n !== 1) {
          out += 'e';
        }
        out += ' haben';
        break;
      case 'minLength':
        out = '';
        n = error.params.limit;
        out += 'darf nicht kürzer als ' + n + ' Zeichen sein';
        break;
      case 'minProperties':
        out = '';
        n = error.params.limit;
        out += 'darf nicht weniger als ' + n + ' Feld';
        if (n !== 1) {
          out += 'er';
        }
        out += ' haben';
        break;
      case 'multipleOf':
        out = 'muss ein Vielfaches von ' + error.params.multipleOf + ' sein';
        break;
      case 'not':
        out = 'muss dem in "not" angegebenen Schema widersprechen';
        break;
      case 'oneOf':
        out = 'muss genau einem der Schemata in "oneOf" entsprechen';
        break;
      case 'pattern':
        out = 'muss diesem Muster entsprechen: "' + error.params.pattern + '"';
        break;
      case 'patternRequired':
        out =
          'muss ein Feld nach folgendem Muster haben "' +
          error.params.missingPattern +
          '"';
        break;
      case 'propertyNames':
        out = "Feldname '" + error.params.propertyName + "' ist ungültig";
        break;
      case 'required':
        out = 'dieses Feld muss ausgefüllt sein';
        break;
      case 'switch':
        out =
          'muss der "switch"-Validierung entsprechen, der Fall ' +
          error.params.caseIndex +
          ' schlägt fehl';
        break;
      case 'type':
        out = 'muss sein: ' + error.params.type;
        break;
      case 'uniqueItems':
        out =
          'darf keine Duplikate enthalten (Elemente #' +
          error.params.j +
          ' und #' +
          error.params.i +
          ' sind gleich)';
        break;
      default:
        continue;
    }

    error.message = out;
  }
};

export default localize_de;
