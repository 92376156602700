declare global {
  interface Window {
    env: any;
  }
}

function readEnv(name: string) {
  if (window.env && window.env[name]) {
    return window.env[name];
  } else {
    return process.env[name];
  }
}

export const BACKEND_URL = readEnv('REACT_APP_BACKEND_URL');
export const ENVIRONMENT = readEnv('REACT_APP_ENVIRONMENT');
export const SENTRY_URL = readEnv('REACT_APP_SENTRY_URL');

if (!BACKEND_URL) {
  console.error('BACKEND_URL is not defined');
}

if (!ENVIRONMENT) {
  console.error('ENVIRONMENT is not defined');
}

export const API_URL = `${BACKEND_URL}/api/v1/`;
export const API_AUTH_PATH = 'auth/';

export const MAX_INSPECTIONS_PENDING = 5;

export const PAGE_TITLE = 'ECHT! SMART WORK';

export const ESW_URL = 'https://echt-smartwork.de';
