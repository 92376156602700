import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ConfirmProviderProps } from 'material-ui-confirm';
import { ConfirmProvider } from 'material-ui-confirm';

export const MuiConfirmProvider: FC<ConfirmProviderProps> = ({
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const defaultOptions = {
    cancellationButtonProps: { variant: 'contained' as 'contained' },
    cancellationText: t('General.abort'),
    confirmationButtonProps: { variant: 'contained' as 'contained' },
    confirmationText: t('General.confirm'),
  };

  return (
    <ConfirmProvider defaultOptions={defaultOptions} {...props}>
      {children}
    </ConfirmProvider>
  );
};

export default MuiConfirmProvider;
