import React, { Fragment, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import DashboardLayout from 'src/layouts/DashboardLayout/DashboardLayout';
import DutDocumentsView from 'src/views/dut/DutDocumentsView/DutDocumentsView';
import DutEventView from 'src/views/dut/DutEventView/DutEventView';
import DutInspectionFormView from 'src/views/dut/DutInspectionFormView/DutInspectionFormView';
import DutInspectionView from 'src/views/dut/DutInspectionView/DutInspectionView';
import DutView from 'src/views/dut/DutView/DutView';
import NotFoundView from 'src/views/errors/NotFoundView/NotFoundView';
import InspectionsPendingView from 'src/views/inspection/InspectionsPendingView/InspectionsPendingView';
import InspectionsView from 'src/views/inspection/InspectionsView/InspectionsView';
import AuthGuard from 'src/components/AuthGuard/AuthGuard';
import DutChildGuard from 'src/components/DutChildGuard/DutChildGuard';
import GuestGuard from 'src/components/GuestGuard/GuestGuard';
import InspectionGuard from 'src/components/InspectionGuard/InspectionGuard';
import LoadingScreen from 'src/components/LoadingScreen/LoadingScreen';
import QrScanGuard from 'src/components/QrScanGuard/QrScanGuard';

type Routes = {
  [key: string]: {
    component?: any;
    guard?: any;
    layout?: any;
    params?: string[];
    path: string;
    routes?: Routes;
  };
};

const routes: Routes = {
  root: {
    component: (): any => <Navigate to={routes.inspections.path} />,
    guard: AuthGuard,
    path: '/',
  },

  login: {
    component: lazy(() => import('src/views/auth/LoginView/LoginView')),
    guard: GuestGuard,
    path: '/login',
  },

  forgotPassword: {
    component: lazy(
      () => import('src/views/auth/ForgotPasswordView/ForgotPasswordView'),
    ),
    path: '/forgot-password',
  },

  resetPassword: {
    component: lazy(
      () => import('src/views/auth/ResetPasswordView/ResetPasswordView'),
    ),
    path: '/reset-password',
  },

  inspections: {
    guard: AuthGuard,
    layout: DashboardLayout,
    component: InspectionsView,
    path: '/inspections',
  },

  inspectionsPending: {
    guard: AuthGuard,
    layout: DashboardLayout,
    component: InspectionsPendingView,
    path: '/pending-inspections',
  },

  trackingQr: {
    component: () => null,
    guard: QrScanGuard,
    path: '/tracking/qr/:trackingCode',
  },

  dut: {
    guard: AuthGuard,
    layout: DashboardLayout,
    path: '/dut/*',
    routes: {
      index: {
        component: DutView,
        get path() {
          return `/:dutId`;
        },
      },

      documents: {
        component: DutDocumentsView,
        guard: DutChildGuard,
        get path() {
          return `/:dutId/documents`;
        },
      },

      event: {
        component: DutEventView,
        guard: DutChildGuard,
        get path() {
          return `/:dutId/event`;
        },
      },

      inspection: {
        component: DutInspectionView,
        guard: DutChildGuard,
        get path() {
          return `/:dutId/inspection/:inspectionId`;
        },
      },

      inspectionForm: {
        component: DutInspectionFormView,
        guard: InspectionGuard,
        get path() {
          return `/:dutId/inspection/:inspectionId/inspectionForm/:inspectionChildId?`;
        },
      },

      fallback: {
        component: (): any => <Navigate to={routes.notFound.path} />,
        path: '',
      },
    },
  },

  notFound: {
    component: NotFoundView,
    path: '/404',
  },

  fallback: {
    component: (): any => <Navigate to={routes.notFound.path} />,
    path: '',
  },
};

export const renderRoutes = (routes: Routes = {}): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Routes>
      {Object.entries(routes).map(([routeName, route]) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={routeName}
            path={route.path}
            element={
              <Guard>
                <Layout>
                  {route.routes ? renderRoutes(route.routes) : <Component />}
                </Layout>
              </Guard>
            }
          />
        );
      })}
    </Routes>
  </Suspense>
);

export default routes;
