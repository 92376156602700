import type { FC } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { jssPreset, StylesProvider } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import type { PersistQueryClientOptions } from '@tanstack/react-query-persist-client';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { de, enIN } from 'date-fns/locale';
import { create } from 'jss';
import { SnackbarOrigin, SnackbarProvider } from 'notistack';

import routes, { renderRoutes } from 'src/routes';
import { theme } from 'src/theme';
import { UserStore } from 'src/services/auth/auth';
import i18n from 'src/services/i18n/i18n';
import { InspectionsProvider } from 'src/services/state/app/InspectionsContext';
import { MuiConfirmProvider } from 'src/services/state/app/MuiConfirmContext';
import GlobalStyles from 'src/components/GlobalStyles/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset/ScrollReset';

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: { networkMode: 'always', retry: false },
    queries: {
      cacheTime: 1000 * 60 * 60 * 24 * 7, // 1 Week
      networkMode: 'always',
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 100 * 60 * 60 * 24, // 1 Day
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

const persistOptions: Omit<PersistQueryClientOptions, 'queryClient'> = {
  maxAge: 1000 * 60 * 60 * 24 * 7, // 1 Week
  persister: persister,
};

const App: FC = () => {
  const locales: { [key: string]: Locale } = { en: enIN, de };
  const jss = create({ plugins: jssPreset().plugins });

  const anchorOrigin: SnackbarOrigin = {
    horizontal: 'center',
    vertical: 'top',
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StylesProvider jss={jss}>
          <PersistQueryClientProvider
            client={queryClient}
            persistOptions={persistOptions}
          >
            <UserStore>
              <SnackbarProvider anchorOrigin={anchorOrigin} maxSnack={3}>
                <InspectionsProvider>
                  <MuiConfirmProvider>
                    <LocalizationProvider
                      adapterLocale={locales[i18n.language]}
                      dateAdapter={AdapterDateFns}
                    >
                      <Router>
                        <GlobalStyles />

                        <ScrollReset />

                        {renderRoutes(routes)}
                      </Router>
                    </LocalizationProvider>
                  </MuiConfirmProvider>
                </InspectionsProvider>
              </SnackbarProvider>
            </UserStore>
          </PersistQueryClientProvider>
        </StylesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
