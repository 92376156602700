import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CircularProgress, Container, Grid } from '@mui/material';
import _ from 'lodash';

import { TestIDs } from 'src/testIDs';
import useInspections from 'src/hooks/useInspections';
import { useDutRead } from 'src/services/state/server/Dut';
import { useInspectionsRead } from 'src/services/state/server/Inspection';
import DutInfo from 'src/components/DutInfo/DutInfo';
import MissingContentBox from 'src/components/MissingContentBox/MissingContentBox';
import Page from 'src/components/Page/Page';

import Header from './Header/Header';
import InspectionFormList from './InspectionFormList/InspectionFormList';
import InspectionStartDialog from './InspectionStartDialog/InspectionStartDialog';

import useStyles from './DutInspectionView.styles';

const ViewTestIDs = TestIDs.views.dut.dutInspectionView;

const DutInspectionView: FC = () => {
  const { t } = useTranslation();
  const { state: inspState } = useInspections();
  const classes = useStyles();
  let { dutId, inspectionId } = useParams<{
    dutId: string;
    inspectionId: string;
  }>();
  dutId = dutId || '';
  inspectionId = inspectionId || '';

  const { data: infiniteDutInspections, isFetching: isFetchingDutInspections } =
    useInspectionsRead({
      requestProps: { dutId: parseInt(dutId) },
    });

  const { data: dut, isFetching: isFetchingDut } = useDutRead({
    requestProps: { dutId: parseInt(dutId) },
  });

  const dutInspections = _.flatten(
    infiniteDutInspections?.pages.map(({ results }) => results),
  );

  const inspectionInProgress = inspState.inspectionInProgress;
  const selectedInspection = dutInspections?.find(
    (inspection) => `${inspection.id}` === `${inspectionId}`,
  );
  const inspInProgressIsSelectedInsp = inspectionInProgress
    ? inspectionInProgress.id === selectedInspection?.id
    : false;
  const hasPictureBefore = inspInProgressIsSelectedInsp
    ? !!inspectionInProgress?.pictureBefore
    : !!selectedInspection?.pictureBefore;

  // Render conditions
  const loading = isFetchingDutInspections || isFetchingDut;
  const showInspectionStartDialog =
    !!selectedInspection &&
    (!hasPictureBefore || !inspInProgressIsSelectedInsp);
  const showInspectionFormList =
    !!selectedInspection &&
    !!inspectionInProgress &&
    !showInspectionStartDialog;

  return (
    <Page
      className={
        (classes.root, showInspectionFormList ? classes.fabMargin : undefined)
      }
      title={t('Views.DutInspection.title')}
    >
      <Container maxWidth={false}>
        <Header
          dutId={parseInt(dutId)}
          title={!loading && !!dut ? dut.name : ''}
        />

        <Grid container spacing={3}>
          {loading && <MissingContentBox content={<CircularProgress />} />}

          {!loading && !dut && (
            <Grid item xs={12}>
              <MissingContentBox
                content={t('Views.DutInspection.noDutFound')}
              />
            </Grid>
          )}

          {!loading && dut && (
            <Grid item xs={12}>
              <DutInfo dut={dut} />
            </Grid>
          )}

          {!loading && dut && !selectedInspection && (
            <Grid item xs={12}>
              <MissingContentBox
                content={t('Views.DutInspection.noInspectionFound')}
              />
            </Grid>
          )}

          {!loading && showInspectionStartDialog && (
            <Grid item xs={12}>
              <InspectionStartDialog
                data-test-id={ViewTestIDs.startDialog.startDialogContainer}
                hasPictureBefore={hasPictureBefore}
                inspInProgressIsSelectedInsp={inspInProgressIsSelectedInsp}
                inspInProgressIsSet={inspectionInProgress?.modified}
                selectedInspection={selectedInspection!}
              />
            </Grid>
          )}

          {!loading && showInspectionFormList && (
            <Grid container direction="column" item xs={12}>
              <InspectionFormList
                data-test-id={
                  ViewTestIDs.inspectionFormList.inspectionFormListContainer
                }
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
};

export default DutInspectionView;
