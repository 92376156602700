/* eslint-disable */ // Disable import sorting to ensure polyfills are applied correctly.
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import '@fontsource/nunito';
import 'src/services/i18n/i18n';
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { enableES5 } from 'immer';

import { SENTRY_URL, ENVIRONMENT } from 'src/constants';
import App from 'src/App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
/* eslint-enable */

const container = document.getElementById('root');
const root = createRoot(container!);

enableES5();

if (SENTRY_URL) {
  Sentry.init({
    dsn: SENTRY_URL,
    environment: ENVIRONMENT,
  });
}

root.render(
  <Sentry.ErrorBoundary>
    <App />
  </Sentry.ErrorBoundary>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
