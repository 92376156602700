import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // Avoid an overlapping FAB when scrolling to the end of the page.
    // The FAB has a 40px height and uses spacing * 3 for its "bottom" property.
    paddingBottom: `calc(${theme.spacing(6)} + 40px)`,
  },

  filterContainer: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },

  fab: {
    bottom: theme.spacing(3),
    position: 'absolute',
    alignSelf: 'center',

    [theme.breakpoints.up('md')]: {
      right: theme.spacing(3), // Move FAB to the right on larger screens. Conveniently overrides 'alignSelf' as well.
    },
  },

  fabIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
