import type { FC } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  Announcement as AnnouncementIcon,
  Assignment as AssignmentIcon,
  Info as InfoIcon,
  InsertDriveFile as InsertDriveFileIcon,
} from '@mui/icons-material';
import type { CardProps, Theme } from '@mui/material';
import {
  Button,
  Card,
  CardActions,
  Grid,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';

import routes from 'src/routes';
import { TestIDs } from 'src/testIDs';
import type { Dut } from 'src/types';

import useStyles from './DutInfo.styles';

const DutInfoTestIDs = TestIDs.components.dutInfo;

interface DutInfoProps extends CardProps {
  className?: string;
  dut: Dut;
}

const DutInfo: FC<DutInfoProps> = ({ className, dut, ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const navigate = useNavigate();
  const showButtonText = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const isDutChild = !!dut.rootId;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const pathPrams = { dutId: `${dut.id}` };
  const dutIndexPath = generatePath(
    '/dut' + routes.dut.routes?.index.path || '',
    pathPrams,
  );
  const dutEventPath = generatePath(
    '/dut' + routes.dut.routes?.event.path || '',
    pathPrams,
  );
  const dutDocumentsPath = generatePath(
    '/dut' + routes.dut.routes?.documents.path || '',
    pathPrams,
  );

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        data-test-id={DutInfoTestIDs.wrapper}
        {...props}
      >
        <CardActions className={classes.buttonWrapper}>
          <Grid container spacing={1}>
            <Grid item>
              <Button
                className={classes.button}
                data-test-id={DutInfoTestIDs.infoButton}
                onClick={(event) => setAnchorEl(event.currentTarget)}
                variant="contained"
              >
                <InfoIcon />
              </Button>
            </Grid>

            <Grid container item spacing={1} xs>
              <Grid item xs={!showButtonText ? 4 : undefined}>
                <Button
                  className={classes.button}
                  data-test-id={DutInfoTestIDs.inspectionsButton}
                  disabled={isDutChild}
                  onClick={() => navigate(dutIndexPath, { replace: true })}
                  fullWidth={!showButtonText}
                  startIcon={showButtonText ? <AssignmentIcon /> : undefined}
                  variant="contained"
                >
                  {showButtonText ? (
                    t('DutInfo.inspections')
                  ) : (
                    <AssignmentIcon />
                  )}
                </Button>
              </Grid>

              <Grid item xs={!showButtonText ? 4 : undefined}>
                <Button
                  className={classes.button}
                  data-test-id={DutInfoTestIDs.documentsButton}
                  disabled={isDutChild}
                  onClick={() => navigate(dutDocumentsPath, { replace: true })}
                  fullWidth={!showButtonText}
                  startIcon={
                    showButtonText ? <InsertDriveFileIcon /> : undefined
                  }
                  variant="contained"
                >
                  {showButtonText ? (
                    t('DutInfo.documents')
                  ) : (
                    <InsertDriveFileIcon />
                  )}
                </Button>
              </Grid>

              <Grid item xs={!showButtonText ? 4 : undefined}>
                <Button
                  className={classes.button}
                  data-test-id={DutInfoTestIDs.eventButton}
                  disabled={isDutChild}
                  onClick={() => navigate(dutEventPath, { replace: true })}
                  fullWidth={!showButtonText}
                  startIcon={showButtonText ? <AnnouncementIcon /> : undefined}
                  variant="contained"
                >
                  {showButtonText ? t('DutInfo.event') : <AnnouncementIcon />}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </CardActions>
      </Card>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        data-test-id={DutInfoTestIDs.infoPopover}
        onClose={() => setAnchorEl(null)}
        open={!!anchorEl}
        PaperProps={{ className: classes.popover }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
      >
        <Typography>
          <b>{t('General.category')}:</b> {dut.category.name}
          <br />
          <b>{t('General.location')}:</b> {dut.location.name}
          <br />
          <b>{t('DutInfo.building')}:</b> {dut.building || '-'}
          <br />
          <b>{t('DutInfo.floor')}:</b> {dut.floor || '-'}
          <br />
          <b>{t('DutInfo.room')}:</b> {dut.room || '-'}
        </Typography>
      </Popover>
    </>
  );
};

export default DutInfo;
