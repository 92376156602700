import type { FC } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import { Close as CloseIcon } from '@mui/icons-material';
import type { CardProps, Theme } from '@mui/material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';

import { MAX_INSPECTIONS_PENDING } from 'src/constants';
import routes from 'src/routes';
import { TestIDs } from 'src/testIDs';
import type { Inspection } from 'src/types';
import useInspections from 'src/hooks/useInspections';
import PhotoDialog from 'src/components/PhotoDialog/PhotoDialog';

import useStyles from './InspectionStartDialog.styles';

const ViewTestIDs = TestIDs.views.dut.dutInspectionView;

interface InspectionStartDialogProps extends CardProps {
  className?: string;
  hasPictureBefore?: boolean;
  inspInProgressIsSelectedInsp?: boolean;
  inspInProgressIsSet?: boolean;
  selectedInspection: Inspection;
}

const InspectionStartDialog: FC<InspectionStartDialogProps> = ({
  className,
  hasPictureBefore,
  inspInProgressIsSelectedInsp,
  inspInProgressIsSet,
  selectedInspection,
  ...props
}) => {
  const { t } = useTranslation();
  const { actions: inspActions, state: inspState } = useInspections();
  const classes = useStyles();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const theme = useTheme<Theme>();
  const [photoDialogOpen, setPhotoDialogOpen] = useState(false);
  const inspectionsPending = inspState.inspectionsPending ?? [];
  const maxInspectionsReached =
    inspectionsPending.length >= MAX_INSPECTIONS_PENDING;
  const isStoredLocally = inspectionsPending.some(
    (inspection) => inspection.id === selectedInspection.id,
  );
  const params = useParams<{
    dutId: string;
  }>();
  const showButtonText = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });
  const dutPath = generatePath('/dut' + routes.dut.routes?.index.path || '', {
    dutId: `${params.dutId}`,
  });

  /**
   *
   */
  const handleSetInspectionInProgress = async () => {
    try {
      // Return if there are too many inspectionsPending already.
      if (!isStoredLocally && maxInspectionsReached) {
        return await confirm({
          cancellationButtonProps: { style: { display: 'none' } },
          description: t('InspectionStartDialog.TooManyDialog.description'),
          title: t('InspectionStartDialog.TooManyDialog.title'),
        });
      }

      // If there already is an inspectionInProgress which is not the selectedInspection
      // make sure the user knows what he is doing, before potentially overwriting
      // the current inspectionInProgress.
      if (inspInProgressIsSet && !inspInProgressIsSelectedInsp) {
        await confirm({
          description: t('InspectionStartDialog.StartDialog.description'),
          title: t('InspectionStartDialog.StartDialog.title'),
        });
      }

      if (!hasPictureBefore) {
        setPhotoDialogOpen(true);
      }

      // Do not (re)set the inspectionInProgress
      if (!inspInProgressIsSelectedInsp) {
        inspActions.setInspectionInProgress(selectedInspection.id ?? null);
      }
    } catch {} // User did not confirm.
  };

  return (
    <>
      <Card className={clsx(classes.root, className)} {...props}>
        <CardHeader title={selectedInspection.type.name} />

        <CardContent>
          <Typography>
            {hasPictureBefore
              ? t('InspectionStartDialog.canContinue')
              : t('InspectionStartDialog.needsPhoto')}
          </Typography>
        </CardContent>

        <CardActions className={classes.buttonWrapper}>
          <Grid item xs={3} md={6}>
            <Button
              data-test-id={ViewTestIDs.startDialog.backButton}
              onClick={() => navigate(dutPath)}
              variant="contained"
            >
              {showButtonText ? t('General.abort') : <CloseIcon />}
            </Button>
          </Grid>

          <Grid container item xs={9} md={6} justifyContent="flex-end">
            <Button
              color="primary"
              data-test-id={ViewTestIDs.startDialog.startButton}
              onClick={handleSetInspectionInProgress}
              variant="contained"
            >
              {hasPictureBefore
                ? t('InspectionStartDialog.continue')
                : t('InspectionStartDialog.startInspection')}
            </Button>
          </Grid>
        </CardActions>
      </Card>

      {photoDialogOpen && (
        <PhotoDialog
          onCapture={(captureResult) =>
            inspActions.setInspectionInProgressPicture({
              imgBase64: captureResult.imgBase64,
              imgSrc: captureResult.dataURL,
              inspectionId: selectedInspection.id,
              isPictureBefore: true,
            })
          }
          onClose={() => setPhotoDialogOpen(false)}
          open={photoDialogOpen}
        />
      )}
    </>
  );
};

export default InspectionStartDialog;
