import type { ChangeEvent, FC } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Webcam from 'react-webcam';
import {
  Close as CloseIcon,
  PhotoCamera as PhotoCameraIcon,
} from '@mui/icons-material';
import type { DialogProps, Theme } from '@mui/material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';

import { TestIDs } from 'src/testIDs';

import useStyles from './PhotoDialog.styles';

const PhotoDialogTestIDs = TestIDs.components.photoDialog;

export interface CaptureResult {
  dataURL: string;
  imgBase64: string;
  imgBlob: Blob;
}

interface PhotoDialogProps extends DialogProps {
  className?: string;
  onCapture?: (captureResult: CaptureResult) => void;
}

const PhotoDialog: FC<PhotoDialogProps> = ({
  className,
  onCapture,
  onClose,
  ...props
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme<Theme>();
  const webcamRef = useRef<Webcam>(null);
  const useNativeCamera = isMobile && !isIOS;
  const fullScreenDialog = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  const [previewImgSrc, setPreviewImgSrc] = useState<
    string | null | undefined
  >();

  /**
   * Wraps a file reader in a promise so the result can be awaited.
   */
  const readFileToBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onloadend = () => resolve(fileReader.result as string);
      fileReader.onerror = (error) => reject(error);
      fileReader.readAsDataURL(file);
    });

  /**
   * Wraps the initialization of an image element in a promise so the onload event can be awaited.
   */
  const loadImg = (imgSrc: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
      img.src = imgSrc;
    });

  /**
   * Resizes a base64 encoded image string by using an html canvas.
   */
  const resizePicture = async (dataURL: string): Promise<string> => {
    // Initialize the image element.
    const img = await loadImg(dataURL);

    // Initialize the canvas element.
    const canvas = document.createElement('canvas');
    const canvasContext = canvas.getContext('2d', { willReadFrequently: true });

    // Calculate the scale for resizing.
    const maxSideLength = 1000;
    const scale =
      img.width > maxSideLength || img.height > maxSideLength
        ? Math.min(maxSideLength / img.width, maxSideLength / img.height)
        : 1;
    const imgWidthScaled = img.width * scale;
    const imgHeightScaled = img.height * scale;

    // Actually resize the image by drawing it on the canvas with the appropriate dimensions.
    canvas.width = imgWidthScaled;
    canvas.height = imgHeightScaled;
    canvasContext?.drawImage(img, 0, 0, imgWidthScaled, imgHeightScaled);

    // Return the resized base64 encoded image string.
    return canvas.toDataURL('image/jpeg');
  };

  /**
   * Resizes the preview image, fetches the image Blob and returns the CaptureResult.
   */
  const fetchPicture = async (dataURL: string): Promise<CaptureResult> => {
    const resizedDataURL = await resizePicture(dataURL);
    const imgBlob = await fetch(resizedDataURL).then((res) => res.blob());

    return { dataURL: resizedDataURL, imgBase64: resizedDataURL, imgBlob };
  };

  /**
   * Captures a screenshot of the current video stream and stores it as a Base64 encoded string.
   * Using the width and height of the actual video stream as the screenshot dimensions will
   * assure that the image has the best possible quality. By default it uses the dimensions it was
   * rendered with.
   * E.g. on a 320px wide viewport the screenshot`s width could only have a maximum width of 320px.
   */
  const capture = useCallback(() => {
    const width = webcamRef?.current?.video?.videoWidth;
    const height = webcamRef?.current?.video?.videoHeight;
    const dimensions = width && height ? { height, width } : undefined;
    const dataURL = webcamRef?.current?.getScreenshot(dimensions);

    setPreviewImgSrc(dataURL);
  }, [webcamRef, setPreviewImgSrc]);

  /**
   * Handles the confirmation of the captured screenshot.
   */
  const handleCaptureConfirm = async (event: any): Promise<void> => {
    const dataURL = previewImgSrc;

    if (dataURL && onCapture) onCapture(await fetchPicture(dataURL));

    onClose!(event, 'backdropClick');
  };

  /**
   * Handles the file change event of the mobile data input field.
   */
  const handleFileChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const file = event?.target?.files?.[0];
    const dataURL = file ? await readFileToBase64(file) : undefined;

    if (dataURL && onCapture) onCapture(await fetchPicture(dataURL));
  };

  /**
   * Handles window focus when the user returns from the native camera app.
   */
  const handleWindowFocus = (event: FocusEvent) => {
    // The window focus event is only fired after the input change event was fired.
    // When "clicking" on the native input element, the window will lose focus.
    // While using the native camera application the user can decide to take and accept a photo or use the native
    // back button to return to the browser. Either way the PhotoDialog component should be unmounted.
    onClose!(event, 'escapeKeyDown');
  };

  /**
   * On mount
   */
  useEffect(() => {
    // Trigger a click event on the native file-input-field on mobile devices.
    // This will open the native camera app as soon as this component mounts.
    // Also add an event listener to be able to register when the user returns to this window after using the camera.
    if (useNativeCamera) {
      window.addEventListener('focus', handleWindowFocus);
      inputRef?.current?.click();
    }

    return () => window.removeEventListener('focus', handleWindowFocus);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // For mobile devices render a simple input element that only allows camera input.
  if (useNativeCamera) {
    return (
      <input
        accept="image/*"
        capture
        onChange={handleFileChange}
        ref={inputRef}
        style={{ display: 'none' }}
        type="file"
      />
    );
  }

  return (
    <Dialog
      className={clsx(classes.root, className)}
      classes={{ paper: classes.MuiDialog_paper }}
      data-test-id={PhotoDialogTestIDs.wrapper}
      fullScreen={fullScreenDialog}
      {...props}
    >
      <DialogTitle variant="h3">
        {t('PhotoDialog.capturePhoto')}

        {onClose && (
          <IconButton
            onClick={(event) => onClose(event, 'backdropClick')}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent>
        <Box className={classes.webCamWrapper}>
          <Webcam
            audio={false}
            ref={webcamRef as any}
            screenshotFormat="image/jpeg"
            style={{ display: previewImgSrc ? 'none' : 'block' }}
            videoConstraints={{ facingMode: 'environment' }}
          />

          <Box
            className={classes.captureButtonContainer}
            style={previewImgSrc ? { visibility: 'hidden' } : undefined}
          >
            <IconButton
              color="primary"
              data-test-id={PhotoDialogTestIDs.captureButton}
              onClick={capture}
              size="large"
            >
              <PhotoCameraIcon />
            </IconButton>
          </Box>

          {previewImgSrc && (
            <img alt={t('PhotoDialog.preview')} src={previewImgSrc} />
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          data-test-id={PhotoDialogTestIDs.retryButton}
          disabled={!previewImgSrc}
          onClick={() => setPreviewImgSrc('')}
          variant="contained"
        >
          {t('General.retry')}
        </Button>

        <Button
          autoFocus
          data-test-id={PhotoDialogTestIDs.confirmButton}
          color="primary"
          disabled={!previewImgSrc}
          onClick={handleCaptureConfirm}
          variant="contained"
        >
          {t('General.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PhotoDialog;
