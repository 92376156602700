import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},

  checkCircleIcon: {
    color: theme.palette.success.main,
  },

  rightListItemIcon: {
    alignSelf: 'center',
  },
}));

export default useStyles;
