import type { FC, ReactNode } from 'react';
import type { UserStoreProps } from 'ai-kit-auth';
import { makeGenericUserStore } from 'ai-kit-auth';

import { API_AUTH_PATH, API_URL } from 'src/constants';
import { User } from 'src/types/user';

/**
 * Authentication is done using ai-kit-authentication
 * https://gitlab.ambient-innovation.com/ai/ai.kit/authentication
 * A backend coupled standardized solution for handling authentication
 * and user management in react-django apps by Ambient Innovation
 */
const { UserStore: AiKitAuthUserStore, useUserStore } =
  makeGenericUserStore<User>({
    authPath: API_AUTH_PATH,
    url: API_URL,
  });

// Temporary fix for removal of intrinsic child property for FC type in react 18. Ai-Kit-Auth still uses react below v18.
const UserStore: FC<UserStoreProps<User> & { children: ReactNode }> =
  AiKitAuthUserStore;

export { UserStore, useUserStore };
