import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { EventCategory } from 'src/types';
import type { GetEventCategoriesProps } from 'src/services/api';
import api from 'src/services/api';
import { useUserStore } from 'src/services/auth/auth';

import { eventKeys } from './keys';

export type UseEventCategoriesQueryOptions = UseQueryOptions<
  EventCategory[],
  unknown,
  EventCategory[],
  ReturnType<typeof eventKeys.categories>
>;

export interface UseEventCategoriesProps {
  queryOptions?: UseEventCategoriesQueryOptions;
  requestProps?: Omit<GetEventCategoriesProps, 'authConfig'>;
}
/**
 * @description Get event categories.
 */
export const useEventCategoriesRead = (
  useEventCategoriesProps?: UseEventCategoriesProps,
) => {
  const { queryOptions, requestProps } = useEventCategoriesProps || {};
  const { axiosRequestConfig: authConfig } = useUserStore();

  return useQuery(
    eventKeys.categories(),

    () =>
      api.getEventCategories({
        authConfig,
        ...requestProps,
      } as GetEventCategoriesProps),

    {
      refetchOnMount: false,
      ...queryOptions,
    },
  );
};
