import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CircularProgress, Container, Grid } from '@mui/material';

import { useDutRead } from 'src/services/state/server/Dut';
import { useEventCategoriesRead } from 'src/services/state/server/Event';
import DutInfo from 'src/components/DutInfo/DutInfo';
import MissingContentBox from 'src/components/MissingContentBox/MissingContentBox';
import Page from 'src/components/Page/Page';

import EventForm from './EventForm/EventForm';
import Header from './Header/Header';

import useStyles from './DutEventView.styles';

const DutEventView: FC = () => {
  const { t } = useTranslation();
  let { dutId } = useParams<{ dutId: string }>();
  dutId = dutId || '';
  const classes = useStyles();

  const { data: eventCategories, isFetching: isFetchingEventCategories } =
    useEventCategoriesRead();

  const { data: dut, isFetching: isFetchingDut } = useDutRead({
    requestProps: { dutId: parseInt(dutId) },
  });

  const loading = isFetchingDut || isFetchingEventCategories;

  return (
    <Page className={classes.root} title={t('Views.DutEvent.title')}>
      <Container maxWidth={false}>
        <Header
          dutId={parseInt(dutId)}
          title={!loading && !!dut ? dut.name : ''}
        />

        <Grid container spacing={3}>
          {loading && <MissingContentBox content={<CircularProgress />} />}

          {!loading && !dut && (
            <MissingContentBox content={t('Views.DutEvent.noDutFound')} />
          )}

          {!loading && !!dut && (
            <Grid item xs={12}>
              <DutInfo dut={dut} />
            </Grid>
          )}

          {!loading && !!dut && !eventCategories && (
            <MissingContentBox content={t('General.somethingWentWrong')} />
          )}

          {!loading && !!dut && !!eventCategories && (
            <Grid item xs={12}>
              <EventForm />
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
};

export default DutEventView;
