import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default useStyles;
