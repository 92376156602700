import type { InternalAxiosRequestConfig } from 'axios';
import axios from 'axios';

import { API_URL } from 'src/constants';
import { toSnakeCase } from 'src/utils';

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 1000 * 60,
});

// Add a request interceptor and transform POST request URL-Params to snake_case.
axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    config.params = toSnakeCase(config.params);

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default axiosInstance;
