import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { GridProps, SelectChangeEvent } from '@mui/material';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import clsx from 'clsx';

import { TestIDs } from 'src/testIDs';
import useInspections from 'src/hooks/useInspections';
import { useInspectionTypesRead } from 'src/services/state/server/Inspection';
import { useLocationsRead } from 'src/services/state/server/Location';

import useStyles from './InspectionsFilter.styles';

const testIDs = TestIDs.views.inspection.inspectionsView.inspectionsFilter;
interface InspectionsFilterProps extends GridProps {
  className?: string;
}

const InspectionsFilter: FC<InspectionsFilterProps> = ({
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const { actions: inspActions, state: inspState } = useInspections();
  const { data: locations = [] } = useLocationsRead();
  const { data: inspectionTypes = [] } = useInspectionTypesRead();
  const classes = useStyles();

  /**
   * Handles changes made to the filter options by updating
   * the inspectionContext.
   */
  const handleChange = (event: SelectChangeEvent) => {
    const filterName = event.target.name;
    const filterValue = event.target.value;

    inspActions.updateFilters({ [filterName]: filterValue });
  };

  return (
    <Grid
      alignItems="center"
      className={clsx(classes.root, className)}
      container
      spacing={1}
      {...props}
    >
      <Grid item xs={12}>
        <FormControl
          data-test-id={testIDs.locationSelect}
          fullWidth
          size="small"
          variant="outlined"
        >
          <InputLabel id="locationLabel">{t('General.location')}</InputLabel>

          <Select
            labelId="locationLabel"
            value={`${inspState.filters.location || ''}`}
            name="location"
            onChange={handleChange}
            label={t('General.location')}
          >
            <MenuItem value="">{t('General.none')}</MenuItem>

            {locations.map((location) => (
              <MenuItem
                data-test-id={testIDs.locationSelectOption}
                key={location.id}
                value={location.id}
              >
                {location.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl
          data-test-id={testIDs.inspectionTypeSelect}
          fullWidth
          size="small"
          variant="outlined"
        >
          <InputLabel id="inspectionTypeLabel">
            {t('General.inspectionType')}
          </InputLabel>

          <Select
            labelId="inspectionTypeLabel"
            value={`${inspState.filters.type || ''}`}
            name="type"
            onChange={handleChange}
            label={t('General.inspectionType')}
          >
            <MenuItem value="">{t('General.none')}</MenuItem>

            {inspectionTypes.map((inspectionType) => (
              <MenuItem key={inspectionType.id} value={inspectionType.id}>
                {inspectionType.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default InspectionsFilter;
