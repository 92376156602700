import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QrReader from 'react-qr-reader';
import { generatePath, useNavigate } from 'react-router-dom';
import { Alert, Box, Button } from '@mui/material';
import clsx from 'clsx';

import routes from 'src/routes';
import { Dut, Inspection } from 'src/types';
import { wait } from 'src/utils';
import { useFetchDut } from 'src/services/state/server/Dut';
import { useInspectionRead } from 'src/services/state/server/Inspection';

import useStyles from './QrScanner.styles';

export interface QrScannerProps
  extends Omit<QrReader.props, 'onError' | 'onScan'> {
  className?: string;
  dutId?: Dut['id'];
  inspectionId?: Inspection['id'];
  matchUuid?: Dut['trackingCode'];
  onError?: (err: any) => void;
  onScan?: (data: string | null) => void;
}

/**
 * @description The scanner tries to find a DUT that matches the uuid within the scanned code.
 * Expected QR-Code pattern: 'http(s)://host/tracking/qr/:uuid'
 */
const QrScanner: FC<QrScannerProps> = ({
  className,
  dutId,
  inspectionId,
  matchUuid,
  onError,
  onScan,
  ...props
}) => {
  const { t } = useTranslation();
  const { fetchDut } = useFetchDut();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const classes = useStyles();
  const navigate = useNavigate();

  const { data: inspection } = useInspectionRead({
    queryOptions: { enabled: !!inspectionId },
    requestProps: { inspectionId: inspectionId! },
  });

  const handleError = (error: any) => {
    console.error(error);
    setError(error);
  };

  const handleScan = async (data: string | null) => {
    if (!data || !!success || !!error) return;

    const trackingCode = data?.split('tracking/qr/')[1];
    const uuidMatch = matchUuid ? matchUuid === trackingCode : true;

    let redirectPath = routes.notFound.path;

    if (!uuidMatch) return handleError(t('QrScannerDialog.noMatchingUuid'));

    // Get DUT`s path or parent Inspection`s path.
    if (!dutId) {
      const dut = await fetchDut({ requestProps: { trackingCode } });

      if (!dut) {
        return handleError(t('QrScannerDialog.notFound'));
      }

      redirectPath = generatePath(
        '/dut' + routes.dut.routes?.index.path || redirectPath,
        {
          dutId: `${dut.id}`,
        },
      );

      if (inspectionId) {
        redirectPath = generatePath(
          '/dut' + routes.dut.routes?.inspection.path || redirectPath,
          {
            dutId: `${dut.id}`,
            inspectionId: `${inspectionId}`,
          },
        );
      }
    }

    // Get child Inspection`s path.
    if (dutId) {
      const inspectionChildId = inspection?.children?.find(
        (inspectionChild) => inspectionChild.dut.trackingCode === trackingCode,
      )?.id;

      if (!inspectionChildId)
        return handleError(t('QrScannerDialog.noMatchingChild'));

      redirectPath = generatePath(
        '/dut' + routes.dut.routes?.inspectionForm.path || redirectPath,
        {
          dutId: `${dutId}`,
          inspectionChildId: `${inspectionChildId}`,
          inspectionId: `${inspectionId}`,
        },
      );
    }

    setSuccess(t('QrScannerDialog.success'));
    await wait(1000);
    navigate(redirectPath);
  };

  return (
    <Box className={clsx(classes.root, className)}>
      <QrReader
        className={classes.qrReader}
        delay={300}
        onError={onError || handleError}
        onScan={onScan || handleScan}
        {...props}
      />

      {(error || success) && (
        <Alert
          action={
            error && (
              <Button onClick={() => setError('')} color="inherit" size="small">
                {t('General.retry')}
              </Button>
            )
          }
          className={classes.alert}
          severity={error ? 'error' : 'success'}
          variant="filled"
        >
          {error}
          {success}
        </Alert>
      )}
    </Box>
  );
};

export default QrScanner;
