import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},

  qrScannerWrapper: {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
  },

  MuiDialog_paper: {
    [theme.breakpoints.up('sm')]: {
      width: '60vw',
    },
  },
}));

export default useStyles;
