import { useEffect, useRef } from 'react';

/**
 * Creates a hook that uses the useRef hook internally for storing the previous value.
 * The ref object is a generic container whose current property is mutable and can hold any value.
 * Inspired by: https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 */
const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export default usePrevious;
