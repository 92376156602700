import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // Avoid an overlapping FAB when scrolling to the end of the page.
    // The FAB has a 40px height and uses spacing * 3 for its "bottom" property.
    paddingBottom: theme.spacing(6) + 40,
  },
}));

export default useStyles;
