import { colors } from '@mui/material';

const palette = {
  type: 'light',

  accent: {
    contrastText: colors.common.white,
    dark: '#38916b',
    light: '#7eedbe',
    main: '#50d09a',
  },

  action: {
    active: '#123e59',
  },

  background: {
    dark: '#f4f6f8',
    default: colors.common.white,
    paper: colors.common.white,
  },

  primary: {
    main: '#064a7c',
  },

  secondary: {
    main: '#075e9f',
  },

  text: {
    primary: '#151515',
    secondary: '#757575',
  },
};

export default palette;
