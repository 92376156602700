import type { ErrorObject } from 'ajv';

import { localize_de as de } from './de';
import { localize_en as en } from './en';

const messagesLocalizer: {
  [localizeKey: string]: (errors: ErrorObject[] | null) => void;
} = { de, en };

export default messagesLocalizer;
