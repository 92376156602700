import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useSnackbar } from 'notistack';

import { MAX_INSPECTIONS_PENDING } from 'src/constants';
import type {
  Inspection,
  InspectionFormValues,
  InspectionInProgress,
  InspectionInProgressChild,
} from 'src/types';
import usePrevious from 'src/hooks/usePrevious';
import { removeEmptyFields } from 'src/services/ajv/ajv';
import { useUserStore } from 'src/services/auth/auth';
import {
  useFetchInspection,
  useInspectionFormUpdate,
} from 'src/services/state/server/Inspection';

export interface FilterParams {
  type?: number;
  location?: number;
}

export interface InspectionsProviderProps {
  children?: ReactNode;
}

interface SetPictureParams {
  imgBase64: string;
  imgSrc: string;
  inspectionId: number;
  isPictureBefore: boolean;
}

interface SetInspectionInProgressFormValuesParams {
  inspectionChildId?: number;
  values: InspectionFormValues;
}

export interface InspectionsContextValue {
  actions: {
    saveInspection: (saveProps: {
      finish?: boolean;
      inspectionId: number;
    }) => Promise<void>;
    setInspectionInProgress: (inspectionId: number | null) => void;
    setInspectionInProgressFormValues: (
      setInspectionInProgressFormValuesParams: SetInspectionInProgressFormValuesParams,
    ) => void;
    setInspectionInProgressPicture: (
      setPictureParams: SetPictureParams,
    ) => void;
    updateFilters: (filterParam: FilterParams) => void;
  };

  getters: {
    getInspectionInProgressChild: (
      inspectionId: number,
    ) => InspectionInProgressChild | undefined;
  };

  state: {
    filters: FilterParams;
    inspectionInProgress: InspectionInProgress | null;
    inspectionsPending: InspectionInProgress[] | null;
  };
}
const InspectionsContext = createContext<InspectionsContextValue>({
  actions: {
    saveInspection: () => new Promise(() => {}),
    setInspectionInProgress: () => {},
    setInspectionInProgressFormValues: () => {},
    setInspectionInProgressPicture: () => {},
    updateFilters: () => {},
  },

  getters: {
    getInspectionInProgressChild: () => undefined,
  },

  state: {
    filters: {},
    inspectionInProgress: null,
    inspectionsPending: null,
  },
});

export const InspectionsProvider: FC<InspectionsProviderProps> = ({
  children,
}) => {
  const { loggedIn: authLoggedIn } = useUserStore();
  const { enqueueSnackbar } = useSnackbar();
  const { fetchInspection, removeInspection } = useFetchInspection();
  const { mutate: updateInspectionForm } = useInspectionFormUpdate();
  const { t } = useTranslation();
  const [filters, setFilters] = useState<FilterParams>({});
  const [inspectionInProgress, setInspectionInProgress] =
    useState<InspectionInProgress | null>(null);
  const [inspectionsPending, setInspectionsPending] = useState<
    InspectionInProgress[]
  >([]);
  const prevInspectionsPending = usePrevious(inspectionsPending);

  /**
   * Updates the filters. Adds, removes and merges the
   * filter options and updates the state accordingly.
   */
  const updateFilters = (filterParams: FilterParams) => {
    Object.entries(filterParams).forEach((filterParam) => {
      const filterName = filterParam[0];
      const filterValue = filterParam[1];

      if (!filterValue) {
        // Remove empty filter parameters by creating a new filters object and omitting filters with no value.
        filterParams = _.omit(filters, [filterName]);
      } else {
        // Merge the previous and current filters.
        filterParams = {
          ...filters,
          [filterName]: filterValue,
        };
      }

      setFilters(filterParams);
    });
  };

  /**
   * Saves all data provided by inspectionInProgress.
   * Wraps the `useInspectionFormUpdate` mutation function in its own promise.
   */
  const saveInspection = ({
    finish,
    inspectionId,
  }: {
    finish?: boolean;
    inspectionId: number;
  }) =>
    new Promise<void>((resolve, reject) => {
      const isInspectionInProgress = inspectionInProgress?.id === inspectionId;
      const inspection = isInspectionInProgress
        ? inspectionInProgress
        : inspectionsPending.find(
            (inspection) => inspection.id === inspectionId,
          );

      if (!inspection) {
        return console.error('no modified inspection that could be saved.');
      }

      const inspectionFormValues = {
        children: inspection.children?.reduce(
          (acc, inspectionChild) => ({
            ...acc,
            [inspectionChild.id]: {
              properties: inspectionChild.inspectionForm.values,
            },
          }),
          {},
        ),
        properties: inspection.inspectionForm.values,
      };

      updateInspectionForm(
        {
          data: inspectionFormValues,
          finish,
          inspectionId: inspection.id,
          inspectionTimestamp: new Date().toISOString(),
          pictureAfterBase64: inspection.pictureAfterAlreadySet
            ? undefined
            : inspection.pictureAfterBase64,
          pictureBeforeBase64: inspection.pictureBeforeAlreadySet
            ? undefined
            : inspection.pictureBeforeBase64,
        },
        {
          onSuccess: () => {
            if (!finish) {
              // Update the `inspectionInProgress` after it was saved successfully.
              _setInspectionInProgress(inspection.id, true);

              // Update pending inspections after successfully saving a potentially pending inspection.
              purgeInspectionsPending();
            } else {
              // Clear the inspectionInProgress.
              if (isInspectionInProgress) {
                _setInspectionInProgress(null);
              }

              // Remove the just finished inspection from cached data.
              removeInspection(inspection.id);
            }

            // Resolve the wrapping `saveInspection` promise.
            resolve();
          },
          // eslint-disable-next-line sort-keys-fix/sort-keys-fix
          onError: (error) => {
            console.error('inspection could not be saved.');

            // Store inspection locally as a side-effect on failed request.
            // Save 'finish' state to be able to either finish or save an inspection within the InspectionsPendingView.
            // Set 'modified' to false to be able to discard changes when editing a pending inspection.
            setInspectionsPending(
              _.unionBy(
                [{ ...inspection, finish, modified: false }],
                inspectionsPending,
                'id',
              ),
            );

            // Also set the InspectionInProgress to 'modified: false' after it was stored locally, to
            // represent the correct state while still viewing the inspectionForm.
            if (isInspectionInProgress) {
              setInspectionInProgress({ ...inspection, modified: false });
            }

            // Reject the wrapping `saveInspection` promise.
            reject(error);
          },
        },
      );
    });

  /**
   * Parses and returns the provided Inspection as InspectionInProgress.
   */
  const prepareInspection = (inspection: Inspection): InspectionInProgress => {
    const _inspectionInProgress: InspectionInProgress = _.cloneDeep(inspection);

    _inspectionInProgress.children = _inspectionInProgress.children
      ? _inspectionInProgress.children.map((inspectionChild) => ({
          ...inspectionChild,
          inspectionForm: {
            ...inspectionChild.inspectionForm,
            values: removeEmptyFields(inspectionChild.inspectionForm.values),
          },
        }))
      : null;

    _inspectionInProgress.inspectionForm.values = removeEmptyFields(
      _inspectionInProgress.inspectionForm.values,
    );

    _inspectionInProgress.modified = false;

    return _inspectionInProgress;
  };

  /**
   * Filters nonexistent or outdated inspections.
   */
  const purgeInspectionsPending = async () => {
    const purgedInspectionsPending = await inspectionsPending.reduce(
      async (
        accPromise: Promise<InspectionInProgress[]>,
        inspectionPending,
      ) => {
        const accumulator = await accPromise;
        const matchingInspection = await fetchInspection({
          requestProps: { inspectionId: inspectionPending.id },
        });

        // If there is no matching inspection remove this inspection from pending inspections.
        if (!matchingInspection) return accumulator;

        const newDate = new Date(matchingInspection.inspectionTimestamp);
        const prevDate = new Date(inspectionPending.inspectionTimestamp);

        // If newDate is after prevDate remove this inspection from pending inspections.
        if (newDate > prevDate) {
          return accumulator;
        }

        // If all conditions were met, keep this inspection as pending inspection.
        accumulator.push(inspectionPending);

        return accumulator;
      },
      Promise.resolve([]),
    );

    const difference =
      inspectionsPending.length - purgedInspectionsPending.length;

    if (difference) {
      enqueueSnackbar(
        t('Notifications.removedInspectionsPending', { count: difference }),
        { variant: 'info' },
      );
    }

    setInspectionsPending(purgedInspectionsPending);
  };

  /**
   * Sets the inspection to be processed.
   * Providing 'null' as parameter will reset the inspectionInProgress.
   */
  const _setInspectionInProgress = async (
    inspectionId: number | null,
    ignorePending: boolean = false,
  ) => {
    if (inspectionId === null) return setInspectionInProgress(null);

    const inspection = await fetchInspection({
      requestProps: { inspectionId },
    });

    const inspectionPending = inspectionsPending.find(
      (inspection) => inspection.id === inspectionId,
    );

    const maxInspectionsPendingReached =
      inspectionsPending.length >= MAX_INSPECTIONS_PENDING;

    if (!inspection) {
      return console.error(`failed to get Inspection ${inspectionId}`);
    }

    if (!inspectionPending && maxInspectionsPendingReached) {
      return console.error(
        `can not add more than ${MAX_INSPECTIONS_PENDING} inspections`,
      );
    }

    // The form_buffer/form_finalise endpoints don't accept requests, where the filePictureBefore/-after
    // is set when the corresponding inspection already has a before/after picture.
    // Thus we have to memorize the state of the server-side inspection here.
    if (inspectionPending && !ignorePending) {
      inspectionPending.pictureAfterAlreadySet = !!inspection.pictureAfter;
      inspectionPending.pictureBeforeAlreadySet = !!inspection.pictureBefore;

      return setInspectionInProgress(inspectionPending);
    }

    return setInspectionInProgress(prepareInspection(inspection));
  };

  /**
   * Sets the inspection picture ('PictureAfter' or 'PictureBefore') for a given inspection.
   */
  const setInspectionInProgressPicture = (params: SetPictureParams): void => {
    if (!inspectionInProgress) {
      return console.error('no inspection in progress to set a picture.');
    }

    const inspectionPicture = params.isPictureBefore
      ? { pictureBefore: params.imgSrc, pictureBeforeBase64: params.imgBase64 }
      : { pictureAfter: params.imgSrc, pictureAfterBase64: params.imgBase64 };

    setInspectionInProgress({
      ...inspectionInProgress,
      ...inspectionPicture,
      modified: true,
    });
  };

  /**
   * Sets the inspectionForm values for a given inspection.
   */
  const setInspectionInProgressFormValues = ({
    inspectionChildId,
    values,
  }: SetInspectionInProgressFormValuesParams): void => {
    if (!inspectionInProgress) {
      return console.error('no inspection in progress to set values.');
    }

    const _values = removeEmptyFields(values);
    let _inspectionInProgress = _.cloneDeep(inspectionInProgress);
    let setModifiedToTrue = false;

    if (!!inspectionChildId) {
      // Is inspection child

      _inspectionInProgress.children?.forEach((inspectionChild, index) => {
        if (inspectionChild.id === inspectionChildId) {
          // Only set 'modified' to 'true' if the form was not
          // modified before and only then check if the values actually changed.
          if (!inspectionChild.modified) {
            setModifiedToTrue = !_.isEqual(
              inspectionChild.inspectionForm.values,
              _values,
            );
          }

          if (_inspectionInProgress.children) {
            _inspectionInProgress.children[index] = {
              ...inspectionChild,
              inspectionForm: {
                ...inspectionChild.inspectionForm,
                values: _values,
              },
            };
          }
        }
      });
    } else {
      // Is parent inspection

      // Only set 'modified' to 'true' if the form was not
      // modified before and only then check if the values actually changed.
      if (!_inspectionInProgress.modified) {
        setModifiedToTrue = !_.isEqual(
          _inspectionInProgress.inspectionForm.values,
          _values,
        );
      }

      _inspectionInProgress = {
        ..._inspectionInProgress,
        inspectionForm: {
          ..._inspectionInProgress.inspectionForm,
          values: _values,
        },
      };
    }

    _inspectionInProgress = {
      ..._inspectionInProgress,
      modified: setModifiedToTrue
        ? setModifiedToTrue
        : inspectionInProgress.modified,
    };

    setInspectionInProgress(_inspectionInProgress);
  };

  /**
   * Returns the child inspection of the InspectionInProgress with the matching inspectionId.
   */
  const getInspectionInProgressChild = (inspectionId: number) =>
    inspectionInProgress?.children?.find(
      (inspectionChild) => inspectionChild.id === inspectionId,
    );

  /**
   * Handle inspectionsPending updates.
   */
  useEffect(() => {
    // Update the inspectionsPending within the local storage.
    if (prevInspectionsPending) {
      localStorage.setItem(
        'inspectionsPending',
        JSON.stringify(inspectionsPending),
      );
    }
  }, [inspectionsPending]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Populate the context's state with persistent or live data on mount if the user is logged in.
   */
  useEffect(() => {
    const offlineLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    const loggedIn = authLoggedIn || offlineLoggedIn;

    if (!loggedIn) return;

    const localInspectionsPending = localStorage.getItem('inspectionsPending');

    if (localInspectionsPending) {
      setInspectionsPending(JSON.parse(localInspectionsPending));
    }
  }, [authLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Purge inspectionsPending on mount.
   */
  useEffect(() => {
    // Remove inspectionsPending if they do no exist within inspections anymore.
    purgeInspectionsPending();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <InspectionsContext.Provider
      value={{
        actions: {
          saveInspection,
          setInspectionInProgress: _setInspectionInProgress,
          setInspectionInProgressFormValues,
          setInspectionInProgressPicture,
          updateFilters,
        },

        getters: {
          getInspectionInProgressChild,
        },

        state: {
          filters,
          inspectionInProgress,
          inspectionsPending,
        },
      }}
    >
      {children}
    </InspectionsContext.Provider>
  );
};

export const InspectionsConsumer = InspectionsContext.Consumer;

export default InspectionsContext;
