import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},

  alert: {
    marginBottom: theme.spacing(2),
  },

  buttonWrapper: {
    padding: theme.spacing(2),
  },

  accentButton: {
    backgroundColor: theme.palette.accent.main,
    color: theme.palette.accent.contrastText,

    '&:hover': {
      backgroundColor: theme.palette.accent.dark,
    },

    '&.Mui-disabled': {
      backgroundColor: theme.palette.accent.light,
    },
  },
}));

export default useStyles;
