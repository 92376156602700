import type { FC } from 'react';
import React from 'react';

import useStyles from './Logo.styles';

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => {
  const classes = useStyles();

  return (
    <img
      alt="Logo"
      className={classes.root}
      src="/static/logo/echt-smartwork-logo.svg"
      {...props}
    />
  );
};

export default Logo;
