import { useContext } from 'react';

import type { InspectionsContextValue } from 'src/services/state/app/InspectionsContext';
import InspectionsContext from 'src/services/state/app/InspectionsContext';

/**
 * @description Provides the InspectionsContext.
 */
const useInspections = (): InspectionsContextValue =>
  useContext(InspectionsContext);

export default useInspections;
