import type { AxiosRequestConfig } from 'axios';

import type {
  Dut,
  EventCategory,
  Inspection,
  InspectionForm,
  InspectionFormValues,
  InspectionType,
  Location,
} from 'src/types';

import axi from './axios';

export interface PaginatedResponse<T = any> {
  count: number | null;
  next: string | null;
  previous: string | null;
  results: T[];
}

export interface RequestProps {
  authConfig: AxiosRequestConfig;
}

export interface PaginatedRequestProps {
  offset?: number | null;
  pageSize?: number | null;
  page?: number | null;
}

export interface GetLocationsProps extends RequestProps {}
/**
 * @description Returns a list of locations.
 */
export const getLocations = async (requestProps: GetLocationsProps) => {
  const { authConfig, ...params } = requestProps;
  const requestUrl = `account/location/`;

  const { data: locations } = await axi.get<Location[]>(requestUrl, {
    ...authConfig,
    params,
  });

  return locations;
};

export interface GetDutByIdProps extends RequestProps {
  dutId: Dut['id'];
  trackingCode?: never;
}
export interface GetDutByTrackingCodeProps extends RequestProps {
  dutId?: never;
  trackingCode: NonNullable<Dut['trackingCode']>;
}
export type GetDutProps = GetDutByIdProps | GetDutByTrackingCodeProps;
/**
 * @description Returns a single DUT. Does only accept the `dutId` or `trackingCode`.
 */
export const getDut = async (requestProps: GetDutProps) => {
  const { authConfig, dutId, trackingCode, ...params } = requestProps;
  const requestUrl = dutId ? `dut/dut/${dutId}/` : `dut/dut/`;

  const dutById = () =>
    axi.get<Dut>(requestUrl, {
      ...authConfig,
      params,
    });

  const dutByTrackingCode = () =>
    axi.get<Dut[]>(requestUrl, {
      ...authConfig,
      params: {
        trackingCode,
        ...params,
      },
    });

  const { data: dut } = dutId ? await dutById() : await dutByTrackingCode();

  // Cast type depending on if a `dutId` or `trackingCode` was provided.
  // Note that the request for `dutId` returns a single DUT while
  // the request for `trackingCode` returns an array with a single DUT item.
  return dutId ? (dut as Dut) : (dut as Dut[])[0];
};

export interface GetDutsProps extends RequestProps {
  onlyWithActiveInspections?: boolean;
}
/**
 * @description Returns a list of DUTs. By default it only returns DUTs with active inspections.
 */
export const getDuts = async (requestProps: GetDutsProps) => {
  const {
    authConfig,
    onlyWithActiveInspections = true,
    ...params
  } = requestProps;
  const requestUrl = onlyWithActiveInspections
    ? `dut/dut/parents_with_active_inspections/`
    : `dut/dut/`;

  const { data: duts } = await axi.get<Dut[]>(requestUrl, {
    ...authConfig,
    params,
  });

  return duts;
};

export interface GetEventCategoriesProps extends RequestProps {}
/**
 * @description Returns a list of Event Categories.
 */
export const getEventCategories = async (
  requestProps: GetEventCategoriesProps,
) => {
  const { authConfig, ...params } = requestProps;
  const requestUrl = `event/event-category/`;

  const { data: eventCategories } = await axi.get<EventCategory[]>(requestUrl, {
    ...authConfig,
    params,
  });

  return eventCategories;
};

export interface PostEventProps extends RequestProps {
  category: EventCategory['id'];
  dut: Dut['id'];
  description: string;
  pictures: Blob[];
  priority: number;
}
/**
 * @description Submit an event.
 */
export const postEvent = (requestProps: PostEventProps) => {
  const { authConfig, pictures, ...data } = requestProps;
  const formData = new FormData();
  const requestUrl = `event/event/`;

  Object.entries(data).forEach(([key, value]) =>
    formData.append(key, `${value}`),
  );

  Object.entries(pictures).forEach(([, value], index) =>
    formData.append(
      `pictures[${index}]`,
      value,
      `dut-${data.dut}-event-picture.jpg`,
    ),
  );

  return axi.post<Inspection>(requestUrl, formData, {
    ...authConfig,
    headers: {
      ...authConfig.headers,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export interface GetInspectionProps extends RequestProps {
  inspectionId: Inspection['id'];
}
/**
 * @description Returns a single inspection.
 */
export const getInspection = async (requestProps: GetInspectionProps) => {
  const { authConfig, inspectionId, ...params } = requestProps;
  const requestUrl = `inspection/inspection/${inspectionId}/`;

  const { data: inspection } = await axi.get<Inspection>(requestUrl, {
    ...authConfig,
    params,
  });

  return inspection;
};

export interface GetInspectionsProps
  extends RequestProps,
    PaginatedRequestProps {
  dutId?: Dut['id'];
  location?: Location['id'];
  type?: InspectionType['id'];
}
/**
 * @description Returns a paginated list of inspections.
 */
export const getInspections = async (requestProps: GetInspectionsProps) => {
  const { authConfig, ...params } = requestProps;
  const requestUrl = `inspection/inspection/`;

  const { data: paginatedInspections } = await axi.get<
    PaginatedResponse<Inspection>
  >(requestUrl, { ...authConfig, params });

  return paginatedInspections;
};

export interface GetInspectionTypesProps extends RequestProps {}
/**
 * @description Returns a list of inspection types.
 */
export const getInspectionTypes = async (
  requestProps: GetInspectionTypesProps,
) => {
  const { authConfig } = requestProps;
  const requestUrl = `inspection/type/`;

  const { data: inspectionTypes } = await axi.get<InspectionType[]>(
    requestUrl,
    authConfig,
  );

  return inspectionTypes;
};

export interface PostInspectionFormProps extends RequestProps {
  data: {
    properties: InspectionFormValues;
  };
  finish?: boolean;
  inspectionId: Inspection['id'];
  inspectionTimestamp: Inspection['inspectionTimestamp'];
  pictureBeforeBase64?: Inspection['pictureBefore'];
  pictureAfterBase64?: Inspection['pictureAfter'];
}
/**
 * @description Submit the inspectionForm.
 */
export const postInspectionForm = async (
  requestProps: PostInspectionFormProps,
) => {
  const {
    authConfig,
    data,
    finish,
    inspectionId,
    inspectionTimestamp,
    pictureAfterBase64,
    pictureBeforeBase64,
  } = requestProps;
  const formData = new FormData();
  const saveOrFinish = finish ? 'finalise' : 'buffer';
  const requestUrl = `inspection/inspection/${inspectionId}/form_${saveOrFinish}/`;

  formData.append('dataJson', JSON.stringify(data));
  formData.append('inspectionTimestamp', inspectionTimestamp);

  if (pictureBeforeBase64) {
    const filePictureBefore = await fetch(pictureBeforeBase64).then((res) =>
      res.blob(),
    );

    formData.append(
      'filePictureBefore',
      filePictureBefore,
      `${inspectionId}-picture-before.jpg`,
    );
  }

  if (pictureAfterBase64) {
    const filePictureAfter = await fetch(pictureAfterBase64).then((res) =>
      res.blob(),
    );

    formData.append(
      'filePictureAfter',
      filePictureAfter,
      `${inspectionId}-picture-after.jpg`,
    );
  }

  const { data: inspectionForm } = await axi.post<InspectionForm>(
    requestUrl,
    formData,
    {
      ...authConfig,
      headers: {
        ...authConfig.headers,
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return inspectionForm;
};
