import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer,
    boxShadow: '0 6px 10px 0 rgba(10, 34, 50, 0.08)',
    backgroundColor: 'white',
  },

  avatar: {
    backgroundColor: theme.palette.error.main,
    fontSize: '1rem',
    height: 32,
    marginRight: theme.spacing(1),
    width: 32,
  },

  toolbar: {
    minHeight: 64,
  },
}));

export default useStyles;
