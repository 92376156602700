import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfirm } from 'material-ui-confirm';
import { CircularProgress, Container, Grid } from '@mui/material';
import type { AxiosError } from 'axios';

import type {
  InspectionForm as InspForm,
  InspectionInProgress,
} from 'src/types';
import useInspections from 'src/hooks/useInspections';
import { useInspectionFormUpdate } from 'src/services/state/server/Inspection';
import InspectionCard from 'src/components/InspectionCard/InspectionCard';
import MissingContentBox from 'src/components/MissingContentBox/MissingContentBox';
import Page from 'src/components/Page/Page';

import Header from './Header/Header';

import useStyles from './InspectionsPendingView.styles';

const InspectionsPendingView: FC = () => {
  const { t } = useTranslation();
  const confirm = useConfirm();
  const classes = useStyles();
  const { actions: inspActions, state: inspState } = useInspections();
  const { isLoading: isSavingInspection } = useInspectionFormUpdate();
  const loading = isSavingInspection;
  const inspectionsPending = inspState.inspectionsPending || [];

  /**
   * Handles error messages.
   */
  const handleErrorMessages = (error: AxiosError<InspForm>) => {
    if (error.response?.data?.errors) {
      // Serverside form error
      confirm({
        cancellationButtonProps: { style: { display: 'none' } },
        description: t(
          'Views.InspectionsPending.ServerErrorDialog.description',
        ),
        title: t('Views.InspectionsPending.ServerErrorDialog.title'),
      });
    } else if (
      error.code === 'ECONNABORTED' ||
      error.message === 'Network Error'
    ) {
      // Client timeout, Poor connection
      confirm({
        cancellationButtonProps: { style: { display: 'none' } },
        description: t('Views.InspectionsPending.TimeoutDialog.description'),
        title: t('Views.InspectionsPending.TimeoutDialog.title'),
      });
    } else {
      // Unknown error
      confirm({
        cancellationButtonProps: { style: { display: 'none' } },
        description: t('General.somethingWentWrong'),
        title: t('General.error'),
      });
    }
  };

  const handleUploadInspection = (inspection: InspectionInProgress) => {
    inspActions
      .saveInspection({
        finish: inspection.finish,
        inspectionId: inspection.id,
      })
      .catch(handleErrorMessages);
  };

  return (
    <Page className={classes.root} title={t('Views.InspectionsPending.title')}>
      <Container maxWidth={false}>
        <Header title={t('Views.InspectionsPending.title')} />

        <Grid container spacing={3}>
          {loading && <MissingContentBox content={<CircularProgress />} />}

          {!loading && !inspectionsPending.length && (
            <MissingContentBox
              content={t('Views.InspectionsPending.noInspectionsFound')}
            />
          )}

          {!loading && !!inspectionsPending.length && (
            <>
              {inspectionsPending.map((inspection) => (
                <Grid key={inspection.id} item xs={12} md={6}>
                  <InspectionCard
                    action={() => handleUploadInspection(inspection)}
                    inspection={inspection}
                    useUploadIcon
                  />
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Container>
    </Page>
  );
};

export default InspectionsPendingView;
