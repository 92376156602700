/**
 * @description Takes a string with several words or an array of strings and returns the first letter
 * of the first word and the first letter of the last word. If there are no spaces to sperate name parts
 * it will return the first two letters of the string.
 * https://stackoverflow.com/a/61651662/9615479
 *
 * eg. John William Doe -> JD
 * eg. JohnWilliamDoe -> JO
 * eg. ["John William", "van Doe"] -> JD
 */
const getInitials = (nameParts: string[] | string): string => {
  const fullName = Array.isArray(nameParts) ? nameParts.join(' ') : nameParts;

  const FirstName = fullName.split(' ')[0];
  const LastName = fullName.split(' ').reduceRight((a) => a);

  // Extract the first two characters of a string
  if (FirstName === LastName) {
    return FirstName.trim().substring(0, 2).toUpperCase();
  }

  // Abbreviate FirstName and LastName
  return [FirstName, LastName]
    .map((name) => name[0])
    .join('')
    .toUpperCase();
};

export default getInitials;
