import type { ErrorObject } from 'ajv';

/**
 * Copied from https://github.com/ajv-validator/ajv-i18n
 */
export const localize_en = (errors: ErrorObject[] | null) => {
  if (!(errors && errors.length)) return;

  for (let i = 0; i < errors.length; i++) {
    const error = errors[i];
    let cond = undefined;
    let n = undefined;
    let out;

    switch (error.keyword) {
      case '$ref':
        out = "can\\'t resolve reference " + error.params.ref;
        break;
      case 'additionalItems':
        out = '';
        n = error.params.limit;
        out += 'should not have more than ' + n + ' item';
        if (n !== 1) {
          out += 's';
        }
        break;
      case 'additionalProperties':
        out = 'should not have additional properties';
        break;
      case 'anyOf':
        out = 'should match some schema in "anyOf"';
        break;
      case 'const':
        out = 'should be equal to constant';
        break;
      case 'contains':
        out = 'should contain a valid item';
        break;
      case 'custom':
        out = 'should pass "' + error.keyword + '" keyword validation';
        break;
      case 'dependencies':
        out = '';
        n = error.params.depsCount;
        out += 'should have propert';
        if (n === 1) {
          out += 'y';
        } else {
          out += 'ies';
        }
        out +=
          ' ' +
          error.params.deps +
          ' when property ' +
          error.params.property +
          ' is present';
        break;
      case 'enum':
        out = 'should be equal to one of predefined values';
        break;
      case 'exclusiveMaximum':
        out = '';
        cond = error.params.comparison + ' ' + error.params.limit;
        out += 'should be ' + cond;
        break;
      case 'exclusiveMinimum':
        out = '';
        cond = error.params.comparison + ' ' + error.params.limit;
        out += 'should be ' + cond;
        break;
      case 'false schema':
        out = 'boolean schema is false';
        break;
      case 'format':
        out = 'should match format "' + error.params.format + '"';
        break;
      case 'formatExclusiveMaximum':
        out = 'formatExclusiveMaximum should be boolean';
        break;
      case 'formatExclusiveMinimum':
        out = 'formatExclusiveMinimum should be boolean';
        break;
      case 'formatMaximum':
        out = '';
        cond = error.params.comparison + ' ' + error.params.limit;
        out += 'should be ' + cond;
        break;
      case 'formatMinimum':
        out = '';
        cond = error.params.comparison + ' ' + error.params.limit;
        out += 'should be ' + cond;
        break;
      case 'if':
        out = 'should match "' + error.params.failingKeyword + '" schema';
        break;
      case 'maximum':
        out = '';
        cond = error.params.comparison + ' ' + error.params.limit;
        out += 'should be ' + cond;
        break;
      case 'maxItems':
        out = '';
        n = error.params.limit;
        out += 'should not have more than ' + n + ' item';
        if (n !== 1) {
          out += 's';
        }
        break;
      case 'maxLength':
        out = '';
        n = error.params.limit;
        out += 'should not be longer than ' + n + ' character';
        if (n !== 1) {
          out += 's';
        }
        break;
      case 'maxProperties':
        out = '';
        n = error.params.limit;
        out += 'should not have more than ' + n + ' propert';
        if (n === 1) {
          out += 'y';
        } else {
          out += 'ies';
        }
        break;
      case 'minimum':
        out = '';
        cond = error.params.comparison + ' ' + error.params.limit;
        out += 'should be ' + cond;
        break;
      case 'minItems':
        out = '';
        n = error.params.limit;
        out += 'should not have less than ' + n + ' item';
        if (n !== 1) {
          out += 's';
        }
        break;
      case 'minLength':
        out = '';
        n = error.params.limit;
        out += 'should not be shorter than ' + n + ' character';
        if (n !== 1) {
          out += 's';
        }
        break;
      case 'minProperties':
        out = '';
        n = error.params.limit;
        out += 'should not have less than ' + n + ' propert';
        if (n === 1) {
          out += 'y';
        } else {
          out += 'ies';
        }
        break;
      case 'multipleOf':
        out = 'should be a multiple of ' + error.params.multipleOf;
        break;
      case 'not':
        out = 'should not be valid according to schema in "not"';
        break;
      case 'oneOf':
        out = 'should match exactly one schema in "oneOf"';
        break;
      case 'pattern':
        out = 'should match pattern "' + error.params.pattern + '"';
        break;
      case 'patternRequired':
        out =
          'should have property matching pattern "' +
          error.params.missingPattern +
          '"';
        break;
      case 'propertyNames':
        out = "property name '" + error.params.propertyName + "' is invalid";
        break;
      case 'required':
        out = 'this field is required';
        break;
      case 'switch':
        out =
          'should pass "switch" keyword validation, case ' +
          error.params.caseIndex +
          ' fails';
        break;
      case 'type':
        out = 'should be ' + error.params.type;
        break;
      case 'uniqueItems':
        out =
          'should not have duplicate items (items ## ' +
          error.params.j +
          ' and ' +
          error.params.i +
          ' are identical)';
        break;
      default:
        continue;
    }

    error.message = out;
  }
};

export default localize_en;
