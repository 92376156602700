import type { FC, ReactNode } from 'react';
import React from 'react';
import { generatePath, Navigate, useParams } from 'react-router-dom';

import routes from 'src/routes';
import useInspections from 'src/hooks/useInspections';

interface InspectionGuardProps {
  children?: ReactNode;
}

/**
 * Shows nested views only if an inspectionInProgress is set and if the inspectionInProgress has a pictureBefore.
 * Otherwise it will redirect to its according DutView.
 */
const InspectionGuard: FC<InspectionGuardProps> = ({ children }) => {
  const { state: inspState } = useInspections();
  const params = useParams<{
    dutId: string;
    inspectionId: string;
  }>();
  const redirectPath = generatePath(
    '/dut' + routes.dut.routes?.inspection.path || '',
    {
      dutId: `${params.dutId}`,
      inspectionId: `${params.inspectionId}`,
    },
  );

  if (!inspState.inspectionInProgress?.pictureBefore) {
    return <Navigate to={redirectPath} />;
  }

  return <>{children}</>;
};

export default InspectionGuard;
