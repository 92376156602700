import type { FC } from 'react';
import React from 'react';
import { Menu as MenuIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Avatar,
  Box,
  Hidden,
  IconButton,
  SvgIcon,
  Toolbar,
  Tooltip,
} from '@mui/material';
import clsx from 'clsx';

import routes from 'src/routes';
import { TestIDs } from 'src/testIDs';
import useInspections from 'src/hooks/useInspections';
import Logo from 'src/components/Logo/Logo';

import Account from './Account/Account';

import useStyles from './TopBar.styles';

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

const TopBar: FC<TopBarProps> = ({
  className,
  onMobileNavOpen = (): void => {},
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { state: inspectionsState } = useInspections();
  const inspectionsPending = inspectionsState.inspectionsPending;

  return (
    <AppBar className={clsx(classes.root, className)} {...props}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="primary" onClick={onMobileNavOpen} size="large">
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>

        <Hidden lgDown>
          <RouterLink to="/">
            <Logo data-test-id={TestIDs.layouts.dashboardLayout.topBar.logo} />
          </RouterLink>
        </Hidden>

        <Box ml={2} flexGrow={1} />

        {!!inspectionsPending?.length && (
          <Box>
            <RouterLink
              data-test-id={
                TestIDs.layouts.dashboardLayout.topBar.inspectionsPendingBadge
              }
              style={{ textDecoration: 'none' }}
              to={routes.inspectionsPending.path}
            >
              <Tooltip title={t('Topbar.inspectionsPendingTooltip') || ''}>
                <Avatar className={classes.avatar}>
                  {inspectionsPending.length}
                </Avatar>
              </Tooltip>
            </RouterLink>
          </Box>
        )}

        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
