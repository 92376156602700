import type { ChangeEvent, FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import {
  ArrowBack as ArrowBackIcon,
  CheckCircle as CheckCircleIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import type { CardProps, Theme } from '@mui/material';
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import type { AxiosError } from 'axios';
import clsx from 'clsx';
import type { FormikErrors, FormikProps } from 'formik';
import { Formik } from 'formik';
import _ from 'lodash';

import routes from 'src/routes';
import { TestIDs } from 'src/testIDs';
import type {
  InspectionForm as InspForm,
  InspectionFormValues as InspFormValues,
} from 'src/types';
import useInspections from 'src/hooks/useInspections';
import { validate } from 'src/services/ajv/ajv';
import {
  useInspectionFormUpdate,
  useInspectionRead,
} from 'src/services/state/server/Inspection';

import InspectionFormInput from './InspectionFormInput/InspectionFormInput';

import useStyles from './InspectionForm.styles';

const ViewTestIDs = TestIDs.views.dut.dutInspectionFormView;

interface InspectionFormProps extends CardProps {
  className?: string;
}

const InspectionForm: FC<InspectionFormProps> = ({ className, ...props }) => {
  const { t } = useTranslation();
  const { isLoading: loading } = useInspectionFormUpdate();
  const {
    actions: inspActions,
    getters: inspGetters,
    state: inspState,
  } = useInspections();
  let { dutId, inspectionId, inspectionChildId } = useParams<{
    dutId: string;
    inspectionId: string;
    inspectionChildId?: string;
  }>();
  dutId = dutId || '';
  inspectionId = inspectionId || '';
  inspectionChildId = inspectionChildId || '';
  const classes = useStyles();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const theme = useTheme<Theme>();
  const formikRef = useRef<FormikProps<InspFormValues>>(null);
  const showButtonText = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });
  const [initialValues, setInitialValues] = useState<InspFormValues>();
  const inspection = inspectionChildId
    ? inspGetters.getInspectionInProgressChild(parseInt(inspectionChildId))
    : inspState.inspectionInProgress;
  const inspectionForm = inspection?.inspectionForm;
  const isDisabled = !!inspState.inspectionInProgress?.pictureAfter;
  const requiredFields = inspectionForm?.required;

  const { data: _originalInsp } = useInspectionRead({
    requestProps: { inspectionId: parseInt(inspectionId) },
  });

  const originalInsp = inspectionChildId
    ? _originalInsp?.children?.find(
        (child) => child.id === parseInt(inspectionChildId!),
      )
    : _originalInsp;
  const originalInspForm = originalInsp?.inspectionForm;
  const inspectionFormListPath = generatePath(
    '/dut' + routes.dut.routes?.inspection.path || '',
    {
      dutId: dutId,
      inspectionId: inspectionId,
    },
  );

  /**
   * Parse the inspectionForm values to make them usable as initial values for Formik.
   */
  const parseInitialValues = (_inspectionForm?: InspForm) => {
    if (!_inspectionForm) return;

    const _initialValues: InspFormValues = {};

    for (const [fieldName] of Object.entries(_inspectionForm.properties)) {
      const initialValue =
        // Explicitly check for numbers as '0' is a valid initial value.
        typeof _inspectionForm.values?.[fieldName] === 'number'
          ? _inspectionForm.values?.[fieldName]
          : _inspectionForm.values?.[fieldName] || '';

      _initialValues[fieldName] = initialValue;
    }

    _initialValues['done'] = _inspectionForm.values['done'];

    return _initialValues;
  };

  /**
   * Scrolls to the first input with an error.
   */
  const scrollToError = (errors: FormikErrors<InspFormValues>) => {
    const keys = Object.keys(errors);
    const selector = `[id="${keys[0]}"]`;
    const errorElement = document.querySelector(selector);

    errorElement?.scrollIntoView();
  };

  /**
   * Handles error messages.
   */
  const handleErrorMessages = (error: AxiosError<InspForm>, done?: boolean) => {
    if (error.response?.data?.errors) {
      // Server-side form error
      formikRef.current?.setErrors(error.response.data.errors);

      confirm({
        cancellationButtonProps: { style: { display: 'none' } },
        description: t('InspectionForm.ServerErrorDialog.description'),
        title: t('InspectionForm.ServerErrorDialog.title'),
      });
    } else if (
      error.code === 'ECONNABORTED' ||
      error.message === 'Network Error'
    ) {
      // Client timeout, Poor connection
      // Inspection will be saved locally.
      // Do not show the error to the user.
      // Redirect to InspectionFormList after confirming if the user intended to mark the inspectionForm as done.
      if (done) navigate(inspectionFormListPath);
    } else {
      // Unknown error
      confirm({
        cancellationButtonProps: { style: { display: 'none' } },
        description: t('General.somethingWentWrong'),
        title: t('General.error'),
      });
    }
  };

  /**
   * Discards changes made to the inspection.
   */
  const handleDiscard = async () => {
    try {
      await confirm({
        description: t('InspectionForm.DiscardDialog.description'),
        title: t('InspectionForm.DiscardDialog.title'),
      });

      const _initialValues = parseInitialValues(originalInspForm);

      inspActions.setInspectionInProgress(parseInt(inspectionId!));

      formikRef.current?.resetForm({
        errors: formikRef.current.errors,
        values: _initialValues,
      });
    } catch {} // User did not confirm.
  };

  /**
   * Handles saving the InspectionForm.
   */
  const handleFormSave = async () => {
    inspActions
      .saveInspection({ inspectionId: parseInt(inspectionId!) })
      .catch(handleErrorMessages);
  };

  /**
   * Handles marking the InspectionForm as done.
   */
  const handleFormDone = async () => {
    if (!formikRef.current) return;

    const { errors, isValid, setFieldValue, submitForm } = formikRef.current;

    // Does not do anything really but setting all fields to `touched` so error messages are displayed.
    submitForm();

    // Exit early and scroll to errors if the form is not valid.
    if (!isValid) return scrollToError(errors);

    // Mark inspection as done, if the form is valid.
    return setFieldValue('done', true);
  };

  /**
   * Handles submitting the InspectionForm as soon as it is marked as done.
   */
  const handleInspectionDone = async () => {
    if (!inspectionForm) return;

    // Exit early and display generic error message, if the inspection context's form was not marked as done yet.
    if (!inspectionForm.values.done) {
      return handleErrorMessages({} as any);
    }

    if (inspectionForm.values.done) {
      return inspActions
        .saveInspection({ inspectionId: parseInt(inspectionId!) })
        .then(() => navigate(inspectionFormListPath))
        .catch((error) => handleErrorMessages(error, true));
    }
  };

  /**
   * Keeps Formik`s and the InspectionContext`s values in sync before validating them.
   */
  const handleValueUpdate = (values: InspFormValues) => {
    if (!inspectionForm) return;

    inspActions.setInspectionInProgressFormValues({
      inspectionChildId: parseInt(inspectionChildId!),
      values,
    });

    return validate({ schema: inspectionForm, values });
  };

  /**
   * Listen to inspectionForm changes and submit the form as soon as the value for `done`
   * was set to `true` on a modified inspection.
   */
  useEffect(() => {
    if (
      !!inspectionForm?.values.done &&
      !!inspState.inspectionInProgress?.modified
    ) {
      handleInspectionDone();
    }
  }, [inspectionForm?.values.done]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Reset Formik`s state as a side effect after the inspection was saved.
   * `inspection.modified` changing to 'false' indicates that the inspection was saved.
   * Hence the Formik`s state should be reset accordingly if it is still mounted.
   */
  useEffect(() => {
    if (inspectionForm && !inspState.inspectionInProgress?.modified) {
      const _initialValues = parseInitialValues(inspectionForm);

      setInitialValues(_initialValues);

      formikRef.current?.resetForm({
        errors: formikRef.current.errors, // Keep errors though, in case an invalid inspection was saved.
        values: _initialValues,
      });
    }
  }, [inspState.inspectionInProgress?.modified]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * After the form was initialized and the initialValues have
   * been set, compare the initialValues of the InspectionFormInProgress and its
   * original InspectionForm to determine if this form was modified.
   * If so, set the form`s values accordingly.
   */
  useEffect(() => {
    const _initialValues = parseInitialValues(inspectionForm);

    if (!_.isEqual(initialValues, _initialValues)) {
      formikRef.current?.setValues(_initialValues!);
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Prepare initial values on mount.
   */
  useEffect(() => {
    if (originalInspForm) {
      setInitialValues(parseInitialValues(originalInspForm));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!inspectionForm || !initialValues) return null;

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formikRef}
      onSubmit={() => {}}
      validate={handleValueUpdate}
      validateOnMount={!inspectionForm.errors}
      initialTouched={inspectionForm.errors || undefined}
      initialErrors={inspectionForm.errors || undefined}
    >
      {({
        dirty,
        errors,
        handleBlur,
        handleChange,
        isValid,
        setFieldValue,
        touched,
        values,
      }) => {
        /**
         * Reset `done` to `false`, without validating, every time any other value has changed.
         */
        const _handleChange = (event: ChangeEvent<any>) => {
          setFieldValue('done', false, false);

          return handleChange(event);
        };

        return (
          <Card className={clsx(classes.root, className)} {...props}>
            <CardHeader title={inspectionForm.title} />

            <CardContent>
              {errors.nonFieldErrors && (
                <Alert
                  className={classes.alert}
                  data-test-id={ViewTestIDs.inspectionForm.nonFieldErrors}
                  id="nonFieldErrors"
                  severity="error"
                  variant="filled"
                >
                  <AlertTitle>{t('InspectionForm.formError')}</AlertTitle>

                  <ul>
                    {/*
                      Override the error type as 'nonFieldErrors' is a special case and
                      always of type 'string[]'
                    */}
                    {(
                      errors as unknown as {
                        [x: string]: string[];
                      }
                    ).nonFieldErrors?.map((nonFieldError) => (
                      <li key={nonFieldError}>{nonFieldError}</li>
                    ))}
                  </ul>
                </Alert>
              )}

              {Object.entries(inspectionForm.properties)
                .sort(([, a], [, b]) => a.order - b.order)
                .map(([fieldName, fieldProps]) => (
                  <InspectionFormInput
                    data-test-id={`${ViewTestIDs.inspectionForm.formInput}-${fieldProps.input}`}
                    error={Boolean(touched[fieldName] && errors[fieldName])}
                    disabled={isDisabled}
                    fieldProps={fieldProps}
                    helperText={touched[fieldName] && errors[fieldName]}
                    id={fieldName}
                    key={fieldName}
                    name={fieldName}
                    onBlur={handleBlur}
                    onChange={_handleChange}
                    required={requiredFields?.includes(fieldName)}
                    value={values[fieldName]}
                  />
                ))}
            </CardContent>

            <CardActions className={classes.buttonWrapper}>
              <Grid container spacing={2}>
                <Grid item xs={8} md={6}>
                  {!dirty ? (
                    <Button
                      data-test-id={ViewTestIDs.inspectionForm.backButton}
                      onClick={() => navigate(inspectionFormListPath)}
                      startIcon={showButtonText ? <ArrowBackIcon /> : null}
                      variant="contained"
                    >
                      {showButtonText ? t('General.back') : <ArrowBackIcon />}
                    </Button>
                  ) : (
                    <Button
                      data-test-id={ViewTestIDs.inspectionForm.discardButton}
                      disabled={isDisabled}
                      onClick={() => handleDiscard()}
                      startIcon={showButtonText ? <DeleteIcon /> : null}
                      variant="contained"
                    >
                      {showButtonText ? (
                        t('InspectionForm.discard')
                      ) : (
                        <DeleteIcon />
                      )}
                    </Button>
                  )}
                </Grid>

                <Grid item xs={4} md={3}>
                  <Button
                    color="primary"
                    data-test-id={ViewTestIDs.inspectionForm.saveButton}
                    disabled={!dirty || loading || isDisabled}
                    endIcon={loading ? <CircularProgress size={18} /> : null}
                    fullWidth
                    onClick={() => handleFormSave()}
                    startIcon={showButtonText ? <SaveIcon /> : null}
                    variant="contained"
                  >
                    {showButtonText ? t('General.save') : <SaveIcon />}
                  </Button>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Button
                    className={isValid ? classes.accentButton : undefined}
                    data-test-id={ViewTestIDs.inspectionForm.finishButton}
                    disabled={
                      (!!values.done && !dirty) || loading || isDisabled
                    }
                    endIcon={loading && <CircularProgress size={18} />}
                    onClick={() => handleFormDone()}
                    fullWidth
                    startIcon={
                      isValid ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />
                    }
                    variant="contained"
                  >
                    {t('InspectionForm.done')}
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        );
      }}
    </Formik>
  );
};

export default InspectionForm;
