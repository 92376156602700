import type { FC, ReactNode } from 'react';
import React from 'react';
import type { BoxProps } from '@mui/material';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';

import i18n from 'src/services/i18n/i18n';

import useStyles from './MissingContentBox.styles';

interface MissingContentBoxProps extends Omit<BoxProps, 'content'> {
  className?: string;
  content?: ReactNode;
}

const MissingContentBox: FC<MissingContentBoxProps> = ({
  className,
  content = i18n.t('General.somethingWentWrong'),
  ...props
}) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)} {...props}>
      {typeof content === 'string' ? (
        <Typography>{content}</Typography>
      ) : (
        <>{content}</>
      )}
    </Box>
  );
};

export default MissingContentBox;
