import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},

  cardContent: {
    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },

  buttonWrapper: {
    padding: theme.spacing(2),
  },

  accentButton: {
    backgroundColor: theme.palette.accent.main,
    color: theme.palette.accent.contrastText,

    '&:hover': {
      backgroundColor: theme.palette.accent.dark,
    },

    '&.Mui-disabled': {
      backgroundColor: theme.palette.accent.light,
    },
  },

  fab: {
    bottom: theme.spacing(3),
    position: 'absolute',
    alignSelf: 'center',

    [theme.breakpoints.up('md')]: {
      right: theme.spacing(3), // Move FAB to the right on larger screens. Conveniently overrides 'alignSelf' as well.
    },
  },

  fabIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
