import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { Location } from 'src/types';
import type { GetLocationsProps } from 'src/services/api';
import api from 'src/services/api';
import { useUserStore } from 'src/services/auth/auth';

import { locationKeys } from './keys';

export type UseLocationsReadQueryOptions = UseQueryOptions<
  Location[],
  unknown,
  Location[],
  ReturnType<typeof locationKeys.list>
>;

export interface UseLocationsReadProps {
  queryOptions?: UseLocationsReadQueryOptions;
  requestProps?: Omit<GetLocationsProps, 'authConfig'>;
}
/**
 * @description Get the locations for the current user.
 */
export const useLocationsRead = (
  useLocationsReadProps?: UseLocationsReadProps,
) => {
  const { queryOptions, requestProps } = useLocationsReadProps || {};
  const { axiosRequestConfig: authConfig } = useUserStore();

  return useQuery(
    locationKeys.list(),

    () =>
      api.getLocations({
        authConfig,
        ...requestProps,
      } as GetLocationsProps),

    {
      refetchOnMount: false,
      ...queryOptions,
    },
  );
};
