import type { FC } from 'react';
import React from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  ArrowForward as ArrowForwardIcon,
  CheckCircle as CheckCircleIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Error as ErrorIcon,
} from '@mui/icons-material';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';

import routes from 'src/routes';
import { TestIDs } from 'src/testIDs';
import type { Inspection } from 'src/types';

import useStyles from './InspectionFormListItem.styles';

const ViewTestIDs = TestIDs.views.dut.dutInspectionView;

interface InspectionFormListItemProps {
  className?: string;
  disabled?: boolean;
  inspection: Inspection;
  onClick?: () => void;
}

const InspectionFormListItem: FC<InspectionFormListItemProps> = ({
  inspection,
  disabled,
  onClick,
  ...props
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams<{
    dutId: string;
    inspectionId: string;
  }>();
  const isInspChild = `${params.inspectionId}` !== `${inspection.id}`;
  const inspectionChildId = isInspChild ? `${inspection.id}` : undefined;

  /**
   * Handles the selection of an InspectionForm and optionally sets the 'inspectionChildId'
   * parameter if the InspectionForm of an Inspection child was selected.
   */
  const handleInspectionFormSelection = () => {
    const pathParams = {
      dutId: `${params.dutId}`,
      inspectionChildId: inspectionChildId as string,
      inspectionId: `${params.inspectionId}`,
    };

    const redirectPath = generatePath(
      '/dut' + routes.dut.routes?.inspectionForm.path || '',
      pathParams,
    );
    console.log(redirectPath);
    navigate(redirectPath);
  };

  return (
    <ListItem
      alignItems="flex-start"
      button
      className={classes.root}
      data-test-id={ViewTestIDs.inspectionFormList.inspectionFormListItem}
      disabled={disabled}
      onClick={onClick ?? handleInspectionFormSelection}
      {...props}
    >
      <ListItemIcon>
        {inspection.inspectionForm.errors ? (
          <ErrorIcon color="error" />
        ) : inspection.inspectionForm.values.done ? (
          <CheckCircleIcon className={classes.checkCircleIcon} />
        ) : (
          <CheckCircleOutlineIcon />
        )}
      </ListItemIcon>

      <ListItemText
        primary={inspection.inspectionForm.title}
        secondary={inspection.dut.name}
      />

      <ListItemIcon className={classes.rightListItemIcon}>
        <ArrowForwardIcon />
      </ListItemIcon>
    </ListItem>
  );
};

export default InspectionFormListItem;
