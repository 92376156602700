import type {
  ChangeEvent,
  FC,
  InputHTMLAttributes,
  KeyboardEventHandler,
} from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import type { FormControlProps, InputProps } from '@mui/material';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup as MUIRadioGroup,
  TextField as MUITextField,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import _ from 'lodash';

import type { InspectionFormField, InspectionFormValue } from 'src/types';

import useStyles from './InspectionFormInput.styles';

export interface InspectionFormInputProps {
  className?: string;
  disabled?: boolean;
  error?: boolean;
  fieldProps: InspectionFormField;
  fullWidth?: boolean;
  helperText?: false | JSX.Element | string | string[];
  id?: string;
  label?: string;
  margin?: FormControlProps['margin'];
  name: string;
  onBlur?: InputProps['onBlur'];
  onChange?: InputProps['onChange'];
  onFocus?: InputProps['onFocus'];
  onKeyDown?: KeyboardEventHandler;
  required?: boolean;
  type?: InputHTMLAttributes<unknown>['type'];
  value?: InspectionFormValue;
  variant?: 'filled' | 'outlined';
}

const InspectionFormInput: FC<InspectionFormInputProps> = ({
  className,
  disabled,
  error,
  fieldProps,
  fullWidth = true,
  helperText = fieldProps.description,
  label = fieldProps.title,
  margin = 'normal',
  name,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  required,
  type = fieldProps.input,
  value,
  variant = 'outlined',
  ...props
}) => {
  const classes = useStyles();
  const shrinkLabel = type === 'date' || type === 'time' ? true : undefined;
  const [debouncedTextFieldValue, setDebouncedTextFieldValue] = useState(value);

  let InspectionFormInput;

  const debouncedHandleChange = _.debounce(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange!(event);
    },
    500,
  );

  const debouncedOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.persist();

      setDebouncedTextFieldValue(event.currentTarget.value);
      debouncedHandleChange(event);
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  // After the onChange callback was called, the passed field value might have been trimmed or altered
  // in other ways by formik. Updated the local debouncedTextFieldValue accordingly.
  useEffect(() => {
    setDebouncedTextFieldValue(value);
  }, [value]);

  /**
   * Creates a list for multiple HelperText elements.
   * Material UI TextField will always render a <p> tag wrapper for its HelperText thus
   * using <ul> and <li> is not and option.
   */
  const FormattedHelperText = () => {
    let formattedHelperText = <>{helperText}</>;

    if (!!helperText && Array.isArray(helperText)) {
      formattedHelperText = (
        <>
          {helperText.map((text) => (
            <span key={text}>
              {text}
              <br />
            </span>
          ))}
        </>
      );
    }

    return formattedHelperText;
  };

  const Legend = (
    <Typography className={clsx(classes.legend, className)} component="legend">
      {label}
      <br />

      <Typography component="p" variant="body2">
        <FormattedHelperText />
      </Typography>
    </Typography>
  );

  const CheckboxGroup = (
    <FormControl
      className={clsx(classes.root, className)}
      component="fieldset"
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      margin={margin}
      required={required}
      {...props}
    >
      <FormLabel component="legend">{label}</FormLabel>

      <FormGroup>
        {fieldProps.items?.enum.map((checkboxValue: string, index: number) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={(value as string[]).includes(checkboxValue)}
                name={name}
                onBlur={(event: any) => onBlur!(event)}
                onChange={onChange}
                value={checkboxValue}
              />
            }
            key={checkboxValue}
            label={fieldProps.items?.enumTitles[index]}
          />
        ))}
      </FormGroup>

      <FormHelperText className={classes.formGroupHelperText}>
        <FormattedHelperText />
      </FormHelperText>
    </FormControl>
  );

  const RadioGroup = (
    <FormControl
      className={clsx(classes.root, className)}
      component="fieldset"
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      margin={margin}
      required={required}
      {...props}
    >
      <FormLabel component="legend">{label}</FormLabel>

      <MUIRadioGroup name={name} onChange={onChange} value={value}>
        {fieldProps.enum?.map((radioValue: string, index: number) => (
          <FormControlLabel
            control={<Radio onBlur={(event: any) => onBlur!(event)} />}
            key={radioValue}
            label={fieldProps.enumTitles?.[index]}
            value={radioValue}
          />
        ))}
      </MUIRadioGroup>

      <FormHelperText className={classes.formGroupHelperText}>
        <FormattedHelperText />
      </FormHelperText>
    </FormControl>
  );

  const TextField = (
    <MUITextField
      className={clsx(classes.root, className)}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      helperText={<FormattedHelperText />}
      InputLabelProps={{ shrink: shrinkLabel }}
      label={label}
      margin={margin}
      name={name}
      onBlur={onBlur}
      onChange={debouncedOnChange}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      required={required}
      type={type}
      value={debouncedTextFieldValue}
      variant={variant}
      {...props}
    />
  );

  switch (type) {
    case 'checkbox':
      InspectionFormInput = CheckboxGroup;
      break;

    case 'date':
      InspectionFormInput = TextField;
      break;

    case 'legend':
      InspectionFormInput = Legend;
      break;

    case 'number':
      InspectionFormInput = TextField;
      break;

    case 'radio':
      InspectionFormInput = RadioGroup;
      break;

    case 'text':
      InspectionFormInput = TextField;
      break;

    case 'time':
      InspectionFormInput = TextField;
      break;

    default:
      InspectionFormInput = TextField;
  }

  return InspectionFormInput;
};

export default InspectionFormInput;
