import type { FC, ReactNode } from 'react';
import { generatePath, Navigate, useParams } from 'react-router-dom';

import routes from 'src/routes';
import { useDutRead } from 'src/services/state/server/Dut';

interface DutChildGuardProps {
  children?: ReactNode;
}

/**
 * Shows views only if the provided DutId does not belong to a DutChild.
 * If the DutId does belong to a DutChild it will redirect to its DutView.
 */
const DutChildGuard: FC<DutChildGuardProps> = ({ children }) => {
  let { dutId } = useParams<{ dutId: string | undefined }>();
  dutId = dutId || '';

  const { data: dut } = useDutRead({
    requestProps: { dutId: parseInt(dutId) },
  });

  const isDutChild = !!dut?.rootId;
  const redirectPath = generatePath(
    '/dut' + routes.dut.routes?.index.path || '',
    {
      dutId: `${dutId}`,
    },
  );

  if (isDutChild) {
    return <Navigate to={redirectPath} />;
  }

  return <>{children}</>;
};

export default DutChildGuard;
