import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    fontSize: '1rem',
    width: 32,
    height: 32,
    marginRight: theme.spacing(1),
  },

  popover: {
    width: 200,
  },

  fullNameText: {
    fontWeight: 'bold',
  },
}));

export default useStyles;
