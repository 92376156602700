import type { Theme } from '@mui/material';
import type { CSSProperties } from '@mui/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  mobileDrawer: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    width: 256,
  },

  mobileDrawerHeader: {
    backgroundColor: 'white',
  },

  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },

  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },

  userName: {
    fontWeight: 'bold',
  },

  divider: {
    backgroundColor: 'white',
    width: '85%',
    margin: '0 auto',
  },

  subHeader: {
    color: 'white',
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.83,
    textTransform: 'uppercase',
  } as CSSProperties,
}));

export default useStyles;
