import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    minHeight: 150,
    height: '100%',
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
