export const TestIDs = {
  layouts: {
    dashboardLayout: {
      topBar: {
        logo: 'layouts-dashboardLayout-topBar-logo',
        userDropDown: 'layouts-dashboardLayout-topBar-userDropDown',
        userDropDownLogout: 'layouts-dashboardLayout-topBar-userDropDownLogout',
        inspectionsPendingBadge:
          'layouts-dashboardLayout-topBar-inspectionsPendingBadge',
      },
      navBar: {
        wrapper: 'layouts-dashboardLayout-navBar-wrapper',
      },
    },
  },

  views: {
    auth: {
      loginView: {
        loginForm: {
          identityField: 'views-auth-loginView-loginForm-identityField',
          passwordField: 'views-auth-loginView-loginForm-passwordField',
          submitError: 'views-auth-loginView-loginForm-submitError',
          loginButton: 'views-auth-loginView-loginForm-loginButton',
          forgotPasswordLink:
            'views-auth-loginView-loginForm-forgotPasswordLink',
        },
      },

      forgotPasswordView: {
        forgotPasswordForm: {
          usernameField:
            'views-auth-forgotPasswordView-forgotPasswordForm-usernameField',
          submitError:
            'views-auth-forgotPasswordView-forgotPasswordForm-submitError',
          submitButton:
            'views-auth-forgotPasswordView-forgotPasswordForm-submitButton',
          backToLoginLink:
            'views-auth-forgotPasswordView-forgotPasswordForm-backToLoginLink',
          successMessage:
            'views-auth-forgotPasswordView-forgotPasswordForm-successMessage',
          backToLoginButton:
            'views-auth-forgotPasswordView-forgotPasswordForm-backToLoginButton',
        },
      },

      resetPasswordView: {
        resetPasswordForm: {
          passwordField:
            'views-auth-resetPasswordView-resetPasswordForm-passwordField',
          repeatPasswordField:
            'views-auth-resetPasswordView-resetPasswordForm-repeatPasswordField',
          submitError:
            'views-auth-resetPasswordView-resetPasswordForm-submitError',
          submitButton:
            'views-auth-resetPasswordView-resetPasswordForm-submitButton',
          backToLoginLink:
            'views-auth-resetPasswordView-resetPasswordForm-backToLoginLink',
          invalidLinkMessage:
            'views-auth-resetPasswordView-resetPasswordForm-invalidLinkMessage',
          backToForgotPasswordButton:
            'views-auth-resetPasswordView-resetPasswordForm-backToForgotPasswordButton',
          successMessage:
            'views-auth-resetPasswordView-resetPasswordForm-successMessage',
          backToLoginButton:
            'views-auth-resetPasswordView-resetPasswordForm-backToLoginButton',
        },
      },
    },

    dut: {
      dutView: {
        header: {
          headerContainer: 'views-dut-dutView-header-headerContainer',
          breadcrumbs: 'views-dut-dutView-header-breadcrumbs',
          headline: 'views-dut-dutView-header-headline',
        },
      },

      dutEventView: {
        header: {
          headerContainer: 'views-dut-dutEventView-header-headerContainer',
          breadcrumbs: 'views-dut-dutEventView-header-breadcrumbs',
          headline: 'views-dut-dutEventView-header-headline',
        },

        eventForm: {
          eventFormContainer:
            'views-dut-dutEventView-eventForm-eventFormContainer',
          categoryField: 'views-dut-dutEventView-eventForm-categoryField',
          priorityField: 'views-dut-dutEventView-eventForm-priorityField',
          descriptionField: 'views-dut-dutEventView-eventForm-descriptionField',
          picturesField: 'views-dut-dutEventView-eventForm-picturesField',
          picturesPreviewContainer:
            'views-dut-dutEventView-eventForm-picturesPreviewContainer',
          picturePreview: 'views-dut-dutEventView-eventForm-picturePreview',
          abortButton: 'views-dut-dutEventView-eventForm-abortButton',
          capturePictureButton:
            'views-dut-dutEventView-eventForm-capturePictureButton',
          submitButton: 'views-dut-dutEventView-eventForm-submitButton',
        },
      },

      dutDocumentsView: {
        header: {
          headerContainer: 'views-dut-DutDocumentsView-header-headerContainer',
          breadcrumbs: 'views-dut-DutDocumentsView-header-breadcrumbs',
          headline: 'views-dut-DutDocumentsView-header-headline',
        },

        documentList: 'views-dut-DutDocumentsView-documentList',
      },

      dutInspectionView: {
        header: {
          headerContainer: 'views-dut-dutInspectionView-header-headerContainer',
          breadcrumbs: 'views-dut-dutInspectionView-header-breadcrumbs',
          headline: 'views-dut-dutInspectionView-header-headline',
        },

        inspectionFormList: {
          inspectionFormListContainer:
            'views-dut-dutInspectionView-inspectionFormList-inspectionFormListContainer',
          inspectionFormListItem:
            'views-dut-dutInspectionView-inspectionFormList-inspectionFormListItem',
          closeButton:
            'views-dut-dutInspectionView-inspectionFormList-closeButton',
          discardButton:
            'views-dut-dutInspectionView-inspectionFormList-discardButton',
          saveButton:
            'views-dut-dutInspectionView-inspectionFormList-saveButton',
          capturePictureButton:
            'views-dut-dutInspectionView-inspectionFormList-capturePictureButton',
          finishButton:
            'views-dut-dutInspectionView-inspectionFormList-finishButton',
        },

        startDialog: {
          startDialogContainer:
            'views-dut-dutInspectionView-startDialog-startDialogContainer',
          backButton: 'views-dut-dutInspectionView-startDialog-backButton',
          startButton: 'views-dut-dutInspectionView-startDialog-startButton',
        },
      },

      dutInspectionFormView: {
        header: {
          headerContainer:
            'views-dut-dutInspectionFormView-header-headerContainer',
          breadcrumbs: 'views-dut-dutInspectionFormView-header-breadcrumbs',
          headline: 'views-dut-dutInspectionFormView-header-headline',
        },

        inspectionForm: {
          inspectionFormContainer:
            'views-dut-dutInspectionFormView-inspectionForm-inspectionFormContainer',
          nonFieldErrors:
            'views-dut-dutInspectionFormView-inspectionForm-nonFieldErrors',
          formInput: 'views-dut-dutInspectionFormView-inspectionForm-formInput',
          backButton:
            'views-dut-dutInspectionFormView-inspectionForm-backButton',
          discardButton:
            'views-dut-dutInspectionFormView-inspectionForm-discardButton',
          saveButton:
            'views-dut-dutInspectionFormView-inspectionForm-saveButton',
          capturePictureButton:
            'views-dut-dutInspectionFormView-inspectionForm-capturePictureButton',
          finishButton:
            'views-dut-dutInspectionFormView-inspectionForm-finishButton',
        },
      },
    },

    inspection: {
      inspectionsView: {
        fab: 'views-inspection-inspectionsView-fab',

        header: {
          headerContainer:
            'views-inspection-inspectionsView-header-headerContainer',
          breadcrumbs: 'views-inspection-inspectionsView-header-breadcrumbs',
          headline: 'views-inspection-inspectionsView-header-headline',
        },

        inspectionsFilter: {
          locationSelect:
            'views-inspection-inspectionsView-inspectionsFilter-locationSelect',
          locationSelectOption:
            'views-inspection-inspectionsView-inspectionsFilter-locationSelectOption',
          inspectionTypeSelect:
            'views-inspection-inspectionsView-inspectionsFilter-inspectionTypeSelect',
        },
      },

      inspectionsPendingView: {
        header: {
          headerContainer:
            'views-inspection-inspectionsPendingView-header-headerContainer',
          breadcrumbs:
            'views-inspection-inspectionsPendingView-header-breadcrumbs',
          headline: 'views-inspection-inspectionsPendingView-header-headline',
        },
      },
    },
  },

  components: {
    dutInfo: {
      wrapper: 'components-dutInfo-wrapper',
      eventButton: 'components-dutInfo-eventButton',
      documentsButton: 'components-dutInfo-documentsButton',
      infoButton: 'components-dutInfo-infoButton',
      infoPopover: 'components-dutInfo-infoPopover',
      inspectionsButton: 'components-dutInfo-inspectionsButton',
    },

    inspectionCard: {
      wrapper: 'components-inspectionCard-wrapper',
      goToInspection: 'components-inspectionCard-goToInspection',
    },

    photoDialog: {
      wrapper: 'components-photoDialog-wrapper',
      captureButton: 'components-photoDialog-captureButton',
      retryButton: 'components-photoDialog-retryButton',
      confirmButton: 'components-photoDialog-confirmButton',
    },

    qrScannerDialog: {
      wrapper: 'components-qrScannerDialog-wrapper',
    },
  },
};
