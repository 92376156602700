import type { FC, ReactNode } from 'react';
import React, { useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import type { SvgIconComponent } from '@mui/icons-material';
import { ListAlt as ListIcon, Update as UpdateIcon } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
} from '@mui/material';

import routes from 'src/routes';
import { TestIDs } from 'src/testIDs';
import { getInitials } from 'src/utils';
import { useUserStore } from 'src/services/auth/auth';
import i18n from 'src/services/i18n/i18n';
import Logo from 'src/components/Logo/Logo';

import NavItem from './NavItem/NavItem';

import useStyles from './NavBar.styles';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: SvgIconComponent;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useUserStore();
  const nameInitials = getInitials(user?.fullName || '');

  const sections: Section[] = [
    {
      items: [
        {
          href: routes.inspections.path,
          icon: ListIcon,
          title: i18n.t('NavBar.sections.inspection.items.inspections.title'),
        },
        {
          href: routes.inspectionsPending.path,
          icon: UpdateIcon,
          title: i18n.t(
            'NavBar.sections.inspection.items.inspectionsPending.title',
          ),
        },
      ],
      subheader: i18n.t('NavBar.sections.inspection.subheader'),
    },
  ];

  const reduceChildRoutes = ({
    acc,
    depth,
    item,
    pathname,
  }: {
    acc: any[];
    depth: number;
    item: Item;
    pathname: string;
  }): any[] => {
    const key = item.title + depth;

    if (item.items) {
      const open = matchPath(String(item.href), pathname);

      acc.push(
        <NavItem
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={Boolean(open)}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            items: item.items,
            pathname,
          })}
        </NavItem>,
      );
    } else {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          info={item.info}
          key={key}
          title={item.title}
        />,
      );
    }

    return acc;
  };

  const renderNavItems = ({
    depth = 0,
    items,
    pathname,
  }: {
    depth?: number;
    items: Item[];
    pathname: string;
  }): React.ReactNode => {
    return (
      <List disablePadding>
        {items.reduce(
          (acc, item) => reduceChildRoutes({ acc, depth, item, pathname }),
          [] as any[],
        )}
      </List>
    );
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            className={classes.mobileDrawerHeader}
            display="flex"
            justifyContent="center"
            p={2}
          >
            <RouterLink to={routes.root.path}>
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>

        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink
              style={{ textDecoration: 'none' }}
              to={routes.root.path}
            >
              <Avatar className={classes.avatar}>{nameInitials}</Avatar>
            </RouterLink>
          </Box>

          <Box mt={2} textAlign="center">
            <Link
              className={classes.userName}
              color="inherit"
              component={RouterLink}
              to={routes.root.path}
              underline="none"
              variant="h5"
            >
              {user?.fullName}
            </Link>
          </Box>
        </Box>

        <Divider className={classes.divider} />

        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader
                  disableGutters
                  disableSticky
                  className={classes.subHeader}
                >
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          data-test-id={TestIDs.layouts.dashboardLayout.navBar.wrapper}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>

      <Hidden lgDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          data-test-id={TestIDs.layouts.dashboardLayout.navBar.wrapper}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
