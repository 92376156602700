import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CircularProgress, Container, Grid } from '@mui/material';

import { TestIDs } from 'src/testIDs';
import useInspections from 'src/hooks/useInspections';
import { useDutRead } from 'src/services/state/server/Dut';
import DutInfo from 'src/components/DutInfo/DutInfo';
import MissingContentBox from 'src/components/MissingContentBox/MissingContentBox';
import Page from 'src/components/Page/Page';

import Header from './Header/Header';
import InspectionForm from './InspectionForm/InspectionForm';

import useStyles from './DutInspectionFormView.styles';

const DutViewTestIDs = TestIDs.views.dut.dutInspectionFormView;

const DutInspectionFormView: FC = () => {
  const { t } = useTranslation();
  const { state: inspState } = useInspections();
  const classes = useStyles();

  let { dutId, inspectionId } = useParams<{
    dutId: string;
    inspectionId: string;
  }>();
  dutId = dutId || '';
  inspectionId = inspectionId || '';

  const { data: dut, isFetching: isFetchingDut } = useDutRead({
    requestProps: { dutId: parseInt(dutId) },
  });

  // Render conditions
  const loading = isFetchingDut;
  const showInspectionForm = !!inspState.inspectionInProgress;

  return (
    <Page className={classes.root} title={t('Views.Dut.title')}>
      <Container maxWidth={false}>
        <Header
          dutId={parseInt(dutId)}
          inspectionId={parseInt(inspectionId)}
          title={!loading && !!dut ? dut.name : ''}
        />

        <Grid container spacing={3}>
          {loading && <MissingContentBox content={<CircularProgress />} />}

          {!loading && !dut && (
            <MissingContentBox content={t('Views.Dut.noDutFound')} />
          )}

          {!loading && dut && (
            <Grid item xs={12}>
              <DutInfo dut={dut} />
            </Grid>
          )}

          {!loading && showInspectionForm && (
            <Grid item xs={12}>
              <InspectionForm
                data-test-id={
                  DutViewTestIDs.inspectionForm.inspectionFormContainer
                }
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
};

export default DutInspectionFormView;
