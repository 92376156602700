import { Dut } from './dut';

export enum InspectionStatus {
  PLANNED = 1,
  APPROVED = 2,
  FINISHED = 3,
  BLOCKED = 4,
}

export type InspectionFormValueType = 'array' | 'number' | 'string';

export type InspectionFormInputType =
  | 'checkbox'
  | 'date'
  | 'legend'
  | 'number'
  | 'radio'
  | 'text'
  | 'time';

export interface InspectionFormField {
  description?: string;
  enum?: string[];
  enumTitles?: string[];
  errorMessage?: {};
  input: InspectionFormInputType;
  items?: {
    enum: string[];
    enumTitles: string[];
    type: 'string';
  };
  maximum?: number;
  maxItems?: number;
  maxLength?: number;
  minimum?: number;
  minItems?: number;
  minLength?: number;
  order: number;
  title: string;
  type: InspectionFormValueType;
}

export interface InspectionFormFields {
  [key: string]: InspectionFormField;
}

export type InspectionFormValue =
  | string
  | string[]
  | number
  | number[]
  | boolean
  | null;

export interface InspectionFormValues {
  [key: string]: InspectionFormValue;
}

export interface InspectionForm {
  $id: string;
  errorMessage?: {};
  errors: {} | null;
  isReady?: boolean;
  properties: InspectionFormFields;
  required: string[] | null;
  title: string;
  type: 'object';
  values: InspectionFormValues;
}

export interface InspectionType {
  id: number;
  name: string;
}

export interface Inspection {
  children: Inspection[] | null;
  dut: Dut;
  id: number;
  inspectionForm: InspectionForm;
  inspectionTimestamp: string;
  pictureBefore: string | null;
  pictureAfter: string | null;
  status:
    | InspectionStatus.PLANNED
    | InspectionStatus.APPROVED
    | InspectionStatus.FINISHED
    | InspectionStatus.BLOCKED;
  type: InspectionType;
}

export interface InspectionInProgressChild extends Inspection {
  modified?: boolean;
}

export interface InspectionInProgress extends InspectionInProgressChild {
  children: InspectionInProgressChild[] | null;
  finish?: boolean;
  pictureAfterBase64?: string;
  pictureBeforeBase64?: string;
  pictureAfterBlob?: Blob;
  pictureBeforeBlob?: Blob;
  pictureAfterAlreadySet?: boolean;
  pictureBeforeAlreadySet?: boolean;
}
