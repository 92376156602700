import type {
  PaletteColor,
  PaletteColorOptions,
  ThemeOptions,
} from '@mui/material';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import muiOverrides from './muiOverrides';
import palette from './palette';
import shadows from './shadows';
import typography from './typography';

declare module '@mui/material/styles' {
  interface Palette {
    accent: PaletteColor;
    background: TypeBackground;
  }

  interface PaletteOptions {
    accent: PaletteColorOptions;
  }

  interface TypeBackground {
    dark: string;
  }
}

export const themesOptions: ThemeOptions = {
  ...muiOverrides,
  palette,
  shadows,
  typography,
};

export const theme = responsiveFontSizes(createTheme(themesOptions));
