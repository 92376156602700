import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { InsertDriveFile as InsertDriveFileIcon } from '@mui/icons-material';
import {
  Card,
  CircularProgress,
  Container,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import _ from 'lodash';

import { TestIDs } from 'src/testIDs';
import { useDutRead } from 'src/services/state/server/Dut';
import DutInfo from 'src/components/DutInfo/DutInfo';
import MissingContentBox from 'src/components/MissingContentBox/MissingContentBox';
import Page from 'src/components/Page/Page';

import Header from './Header/Header';

import useStyles from './DutDocumentsView.styles';

const ViewTestIDs = TestIDs.views.dut.dutDocumentsView;

const DutDocumentsView: FC = () => {
  const { t } = useTranslation();
  let { dutId } = useParams<{ dutId: string }>();
  dutId = dutId || '';
  const classes = useStyles();

  const { data: dut, isFetching: isFetchingDut } = useDutRead({
    requestProps: { dutId: parseInt(dutId) },
  });

  const loading = isFetchingDut;
  const hasItems = !!dut?.documents.length;
  const categoryGroups = dut ? _.groupBy(dut.documents, 'categoryName') : [];

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');

    if (newWindow) newWindow.opener = null;
  };

  return (
    <Page className={classes.root} title={t('Views.DutDocuments.title')}>
      <Container maxWidth={false}>
        <Header
          dutId={parseInt(dutId)}
          title={!loading && !!dut ? dut.name : ''}
        />

        <Grid container spacing={3}>
          {loading && <MissingContentBox content={<CircularProgress />} />}

          {!loading && !dut && (
            <MissingContentBox content={t('Views.DutDocuments.noDutFound')} />
          )}

          {!loading && dut && (
            <Grid item xs={12}>
              <DutInfo dut={dut} />
            </Grid>
          )}

          {!loading && dut && !hasItems && (
            <MissingContentBox
              content={t('Views.DutDocuments.noDocumentsFound')}
            />
          )}

          {!loading && dut && hasItems && (
            <Grid item xs={12}>
              <Card>
                {Object.entries(categoryGroups).map(
                  ([categoryName, categoryGroupDocuments]) => (
                    <List
                      className={classes.root}
                      data-test-id={ViewTestIDs.documentList}
                      key={categoryName}
                      subheader={
                        <ListSubheader>
                          {categoryName === 'undefined'
                            ? t('Views.DutDocuments.other')
                            : categoryName}
                        </ListSubheader>
                      }
                    >
                      {categoryGroupDocuments.map((document) => (
                        <ListItemButton
                          key={document.id}
                          onClick={() => openInNewTab(document.files[0].file)}
                        >
                          <ListItemIcon>
                            <InsertDriveFileIcon />
                          </ListItemIcon>

                          <ListItemText primary={document.name} />
                        </ListItemButton>
                      ))}
                    </List>
                  ),
                )}
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
};

export default DutDocumentsView;
