import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { NavigateNext as NavigateNextIcon } from '@mui/icons-material/';
import type { GridProps } from '@mui/material';
import { Box, Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import clsx from 'clsx';

import routes from 'src/routes';
import { TestIDs } from 'src/testIDs';

import useStyles from './Header.styles';

const testIDs = TestIDs.views.inspection.inspectionsView;

interface HeaderProps extends GridProps {
  className?: string;
  title: string;
}

const Header: FC<HeaderProps> = ({ className, title, ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.root, className)}
      data-test-id={testIDs.header.headerContainer}
    >
      <Grid
        alignItems="center"
        container
        justifyContent="space-between"
        spacing={3}
        {...props}
      >
        <Grid item>
          <Breadcrumbs
            aria-label="breadcrumb"
            data-test-id={testIDs.header.breadcrumbs}
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              color="inherit"
              component={RouterLink}
              to={routes.root.path}
              variant="body1"
            >
              {t('NavBar.sections.inspection.subheader')}
            </Link>

            <Typography color="textPrimary" variant="body1">
              {t('NavBar.sections.inspection.items.inspections.title')}
            </Typography>
          </Breadcrumbs>

          <Typography
            color="textPrimary"
            data-test-id={testIDs.header.headline}
            variant="h3"
          >
            {title}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Header;
