import type { FC } from 'react';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import {
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  CenterFocusWeak as ScanIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  PhotoCamera as PhotoCameraIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import type { CardProps, Theme } from '@mui/material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Fab,
  Grid,
  List,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import type { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';

import routes from 'src/routes';
import { TestIDs } from 'src/testIDs';
import type { Dut, InspectionForm as InspForm } from 'src/types';
import useInspections from 'src/hooks/useInspections';
import { useInspectionFormUpdate } from 'src/services/state/server/Inspection';
import PhotoDialog from 'src/components/PhotoDialog/PhotoDialog';
import QrScannerDialog from 'src/components/QrScannerDialog/QrScannerDialog';

import InspectionFormListItem from './InspectionFormListItem/InspectionFormListItem';

import useStyles from './InspectionFormList.styles';

const ViewTestIDs = TestIDs.views.dut.dutInspectionView;

interface InspectionFormListProps extends CardProps {
  className?: string;
}

const InspectionFormList: FC<InspectionFormListProps> = ({ ...props }) => {
  const { t } = useTranslation();
  let { dutId } = useParams<{
    dutId: string;
  }>();
  dutId = dutId || '';
  const { isLoading: loading } = useInspectionFormUpdate();
  const { actions: inspActions, state: inspState } = useInspections();
  const classes = useStyles();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const theme = useTheme<Theme>();
  const { enqueueSnackbar } = useSnackbar();
  const showButtonText = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });
  const [photoDialogOpen, setPhotoDialogOpen] = useState(false);
  const [qrScannerDialogOpen, setQrScannerDialogOpen] = useState(false);
  const [inspChildTrackingCode, setInspChildTrackingCode] =
    useState<Dut['trackingCode']>();
  const inspectionInProgress = inspState.inspectionInProgress!;
  const parentFormDone = inspectionInProgress?.inspectionForm.values.done;
  const childFormsDone = inspectionInProgress?.children?.every(
    (inspectionChild) => inspectionChild.inspectionForm.values.done,
  );
  const allFormsDone = inspectionInProgress?.children?.length
    ? parentFormDone && childFormsDone
    : parentFormDone;
  const hasPictureAfter = !!inspectionInProgress.pictureAfter;
  const canFinish = hasPictureAfter && allFormsDone;
  const dutPath = generatePath('/dut' + routes.dut.routes?.index.path || '', {
    dutId: `${dutId}`,
  });

  /**
   * Discards changes made to the inspectionInProgress
   */
  const handleDiscard = async () => {
    try {
      await confirm({
        description: t('InspectionFormList.DiscardDialog.description'),
        title: t('InspectionFormList.DiscardDialog.title'),
      });

      inspActions.setInspectionInProgress(inspectionInProgress.id);
    } catch {} // User did not confirm.
  };

  /**
   * Handles error messages.
   */
  const handleErrorMessages = (
    error: AxiosError<InspForm>,
    finish?: boolean,
  ) => {
    if (error.response?.data?.errors) {
      // Server-side form error
      confirm({
        cancellationButtonProps: { style: { display: 'none' } },
        description: t('InspectionForm.ServerErrorDialog.description'),
        title: t('InspectionForm.ServerErrorDialog.title'),
      });
    } else if (
      error.code === 'ECONNABORTED' ||
      error.message === 'Network Error'
    ) {
      // Client timeout or poor connection.
      // Inspection will be saved locally.
      // Redirect to InspectionsView after confirming if the user intended to finish the inspection.
      confirm({
        cancellationButtonProps: { style: { display: 'none' } },
        description: t('InspectionForm.TimeoutDialog.description'),
        title: t('InspectionForm.TimeoutDialog.title'),
      }).then(() => {
        if (finish) navigate(generatePath(routes.root.path));
      });
    } else {
      // Unknown error
      confirm({
        cancellationButtonProps: { style: { display: 'none' } },
        description: t('General.somethingWentWrong'),
        title: t('General.error'),
      });
    }
  };

  /**
   * Handles saving the inspectionInProgress.
   */
  const handleSaveInspectionInProgress = async () => {
    inspActions
      .saveInspection({ inspectionId: inspectionInProgress.id })
      .catch(handleErrorMessages);
  };

  /**
   * Handles finishing the inspectionInProgress.
   */
  const handleFinishInspectionInProgress = async () => {
    // If there is no pictureAfter yet cancel submission.
    // The user has to press the "finish" button, after successfully capturing a pictureAfter.
    if (!hasPictureAfter) return;

    inspActions
      .saveInspection({
        finish: true,
        inspectionId: inspectionInProgress.id,
      })
      .then(() => {
        // After successfully finishing an inspection show a success message and redirect to the DUT.
        enqueueSnackbar(t('InspectionFormList.success'), {
          variant: 'success',
        });
        navigate(generatePath(routes.root.path));
      })
      .catch((error) => handleErrorMessages(error, true));
  };

  /**
   *
   */
  const handleQrScannerDialog = (
    _inspChildTrackingCode?: Dut['trackingCode'],
  ) => {
    if (!qrScannerDialogOpen) {
      setInspChildTrackingCode(_inspChildTrackingCode);
      setQrScannerDialogOpen(true);
    } else {
      setInspChildTrackingCode(undefined);
      setQrScannerDialogOpen(false);
    }
  };

  if (!inspectionInProgress) return null;

  return (
    <>
      <Card className={classes.root} {...props}>
        <CardHeader title={inspectionInProgress.type.name} />

        <CardContent className={classes.cardContent}>
          <List>
            <InspectionFormListItem
              disabled={hasPictureAfter}
              inspection={inspectionInProgress}
            />

            {inspectionInProgress.children?.map((inspectionChild) => (
              <Fragment key={inspectionChild.id}>
                <Divider />

                <InspectionFormListItem
                  disabled={hasPictureAfter}
                  inspection={inspectionChild}
                  onClick={() =>
                    handleQrScannerDialog(inspectionChild.dut.trackingCode)
                  }
                />
              </Fragment>
            ))}
          </List>
        </CardContent>

        <CardActions className={classes.buttonWrapper}>
          <Grid container spacing={2}>
            <Grid item xs={4} md={3}>
              {!inspectionInProgress?.modified ? (
                <Button
                  data-test-id={ViewTestIDs.inspectionFormList.closeButton}
                  onClick={() => navigate(dutPath)}
                  variant="contained"
                >
                  {showButtonText ? t('General.abort') : <CloseIcon />}
                </Button>
              ) : (
                <Button
                  data-test-id={ViewTestIDs.inspectionFormList.discardButton}
                  onClick={handleDiscard}
                  startIcon={showButtonText ? <DeleteIcon /> : null}
                  variant="contained"
                >
                  {showButtonText ? (
                    t('InspectionFormList.discard')
                  ) : (
                    <DeleteIcon />
                  )}
                </Button>
              )}
            </Grid>

            <Grid item xs={4} md={3}>
              <Button
                color="primary"
                data-test-id={ViewTestIDs.inspectionFormList.saveButton}
                disabled={
                  hasPictureAfter || !inspectionInProgress?.modified || loading
                }
                endIcon={loading ? <CircularProgress size={18} /> : null}
                fullWidth
                onClick={handleSaveInspectionInProgress}
                startIcon={showButtonText ? <SaveIcon /> : null}
                variant="contained"
              >
                {showButtonText ? t('General.save') : <SaveIcon />}
              </Button>
            </Grid>

            <Grid item xs={4} md={3}>
              <Button
                className={hasPictureAfter ? classes.accentButton : undefined}
                color="primary"
                data-test-id={
                  ViewTestIDs.inspectionFormList.capturePictureButton
                }
                disabled={!allFormsDone || hasPictureAfter || loading}
                fullWidth
                onClick={() => setPhotoDialogOpen(true)}
                startIcon={showButtonText ? <PhotoCameraIcon /> : null}
                variant="contained"
              >
                {showButtonText ? (
                  t('InspectionFormList.capturePictureAfter')
                ) : (
                  <PhotoCameraIcon />
                )}
              </Button>
            </Grid>

            <Grid item xs={12} md={3}>
              <Button
                className={canFinish ? classes.accentButton : undefined}
                data-test-id={ViewTestIDs.inspectionFormList.finishButton}
                disabled={!canFinish || loading}
                endIcon={loading ? <CircularProgress size={18} /> : null}
                fullWidth
                onClick={handleFinishInspectionInProgress}
                startIcon={<AssignmentTurnedInIcon />}
                variant="contained"
              >
                {t('InspectionFormList.finish')}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>

      <Fab
        className={classes.fab}
        color="primary"
        onClick={() => handleQrScannerDialog()}
        size="medium"
        variant="extended"
      >
        <ScanIcon className={classes.fabIcon} />
        {t('QrScannerDialog.scanQrCode')}
      </Fab>

      {qrScannerDialogOpen && (
        <QrScannerDialog
          dutId={parseInt(dutId)}
          inspectionId={inspectionInProgress.id}
          matchUuid={inspChildTrackingCode}
          onClose={() => handleQrScannerDialog()}
          open={qrScannerDialogOpen}
        />
      )}

      {photoDialogOpen && (
        <PhotoDialog
          onCapture={(captureResult) =>
            inspActions.setInspectionInProgressPicture({
              imgBase64: captureResult.imgBase64,
              imgSrc: captureResult.dataURL,
              inspectionId: inspectionInProgress.id,
              isPictureBefore: false,
            })
          }
          onClose={() => setPhotoDialogOpen(false)}
          open={photoDialogOpen}
        />
      )}
    </>
  );
};

export default InspectionFormList;
