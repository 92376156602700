import type { FC, ReactNode } from 'react';
import { generatePath, Navigate, useParams } from 'react-router-dom';

import { ESW_URL } from 'src/constants';
import routes from 'src/routes';
import { useUserStore } from 'src/services/auth/auth';
import { useDutRead } from 'src/services/state/server/Dut';
import LoadingScreen from 'src/components/LoadingScreen/LoadingScreen';

interface QrScanGuardProps {
  children?: ReactNode;
}

/**
 * Shows nested views only to logged in users and redirects logged out users accordingly.
 */
const QrScanGuard: FC<QrScanGuardProps> = ({ children }) => {
  let { trackingCode } = useParams<{ trackingCode: string }>();
  trackingCode = trackingCode || '';
  const { loggedIn: authLoggedIn, loading } = useUserStore();
  const offlineLoggedIn =
    !navigator.onLine && localStorage.getItem('isLoggedIn') === 'true';
  const loggedIn = authLoggedIn || offlineLoggedIn;
  const { data: dut } = useDutRead({
    requestProps: { trackingCode: trackingCode },
  });
  let redirectPath = '';

  if (navigator.onLine) {
    localStorage.setItem('isLoggedIn', loggedIn.toString());
  }

  if (loading) {
    return <LoadingScreen />;
  }

  if (!loggedIn) {
    window.location.assign(ESW_URL);
    return <></>;
  }

  if (!dut) {
    return <Navigate to={routes.login.path} />;
  }

  redirectPath = generatePath(
    '/dut' + routes.dut.routes?.index.path || routes.notFound.path,
    {
      dutId: `${dut.id}`,
    },
  );

  return <Navigate to={redirectPath} />;
};

export default QrScanGuard;
