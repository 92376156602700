import type { FC, MouseEvent } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ArrowForward as ArrowForwardIcon,
  Backup as UploadIcon,
  BackupOutlined as BackupOutlinedIcon,
} from '@mui/icons-material';
import type { CardProps, Theme } from '@mui/material';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';

import { TestIDs } from 'src/testIDs';
import type { Inspection } from 'src/types';
import { InspectionStatus } from 'src/types';
import useInspections from 'src/hooks/useInspections';

import useStyles from './InspectionCard.styles';

const testIDs = TestIDs.components.inspectionCard;

interface InspectionCardProps extends CardProps {
  action?: (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    inspection: Inspection,
  ) => void;
  className?: string;
  emphasizeInsp?: boolean;
  inspection: Inspection;
  useUploadIcon?: boolean;
}

const InspectionCard: FC<InspectionCardProps> = ({
  action,
  className,
  emphasizeInsp,
  inspection,
  useUploadIcon,
  ...props
}) => {
  const { t } = useTranslation();
  const theme = useTheme<Theme>();
  const classes = useStyles();
  const { state: inspState } = useInspections();
  const isInspectionInProgress =
    inspState.inspectionInProgress?.id === inspection.id;
  const isInspectionPending = inspState.inspectionsPending?.some(
    (inspectionPending) => inspection.id === inspectionPending.id,
  );

  const title = emphasizeInsp ? inspection.type.name : inspection.dut.name;

  const Description = () =>
    emphasizeInsp ? (
      <>
        {t(`General.InspectionStatus.${InspectionStatus[inspection.status]}`)}
        {isInspectionInProgress && ` (${t('General.inProgress')})`}
      </>
    ) : (
      <>
        {inspection.type.name}
        <br />
        {inspection.dut.location.name}
        <br />
        <br />
        <b>{t('DutInfo.building')}:</b> {inspection.dut.building || '-'}
        <br />
        <b>{t('DutInfo.floor')}:</b> {inspection.dut.floor || '-'}
        <br />
        <b>{t('DutInfo.room')}:</b> {inspection.dut.room || '-'}
      </>
    );

  const CardHeaderAndContent = () => (
    <>
      <CardHeader
        action={
          isInspectionPending && !useUploadIcon ? (
            <BackupOutlinedIcon color="disabled" />
          ) : undefined
        }
        className={classes.cardHeader}
        classes={{
          action: classes.cardHeaderAction,
          content: classes.cardHeaderContent,
        }}
        title={title}
      />

      <CardContent className={classes.cardContent}>
        <Box className={classes.typographyContainer}>
          <Typography variant="body1" component="p">
            <Description />
          </Typography>
        </Box>

        {!!action && (
          <Box className={classes.buttonContainer}>
            <IconButton data-test-id={testIDs.goToInspection} size="large">
              {useUploadIcon ? (
                <UploadIcon style={{ color: theme.palette.accent.main }} />
              ) : (
                <ArrowForwardIcon
                  style={{ color: theme.palette.accent.main }}
                />
              )}
            </IconButton>
          </Box>
        )}
      </CardContent>
    </>
  );

  return (
    <Card className={className} data-test-id={testIDs.wrapper} {...props}>
      {!!action ? (
        <CardActionArea
          component="div"
          disableRipple
          onClick={(event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) =>
            action(event, inspection)
          }
        >
          <CardHeaderAndContent />
        </CardActionArea>
      ) : (
        <CardHeaderAndContent />
      )}
    </Card>
  );
};

export default InspectionCard;
