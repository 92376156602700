import type { FC } from 'react';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import routes from 'src/routes';
import { TestIDs } from 'src/testIDs';
import { getInitials } from 'src/utils';
import { useUserStore } from 'src/services/auth/auth';

import useStyles from './Account.styles';

const Account: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const ref = useRef<any>(null);
  const { user, logout } = useUserStore();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState<boolean>(false);
  const nameInitials = getInitials(user?.fullName || '');

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      localStorage.clear();
      navigate(routes.login.path);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Box
        alignItems="center"
        component={ButtonBase}
        data-test-id={TestIDs.layouts.dashboardLayout.topBar.userDropDown}
        display="flex"
        onClick={handleOpen}
        // @ts-ignore
        ref={ref}
      >
        <Avatar className={classes.avatar}>{nameInitials}</Avatar>

        <Hidden mdDown>
          <Typography
            variant="h6"
            color="primary"
            className={classes.fullNameText}
          >
            {user?.fullName}
          </Typography>
        </Hidden>
      </Box>

      <Menu
        anchorEl={ref.current}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        keepMounted
        onClose={handleClose}
        open={isOpen}
        PaperProps={{ className: classes.popover }}
      >
        <MenuItem
          onClick={handleLogout}
          data-test-id={
            TestIDs.layouts.dashboardLayout.topBar.userDropDownLogout
          }
        >
          {t('Topbar.logout')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default Account;
