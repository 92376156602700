import { colors } from '@mui/material';

const typography = {
  fontFamily: 'Nunito',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,

  h1: {
    get fontWeight() {
      return typography.fontWeightBold;
    },
    fontSize: 35,
  },

  h2: {
    get fontWeight() {
      return typography.fontWeightBold;
    },
    fontSize: 29,
  },

  h3: {
    get fontWeight() {
      return typography.fontWeightBold;
    },
    fontSize: 24,
  },

  h4: {
    get fontWeight() {
      return typography.fontWeightBold;
    },
    fontSize: 20,
  },

  h5: {
    get fontWeight() {
      return typography.fontWeightBold;
    },
    fontSize: 16,
  },

  h6: {
    get fontWeight() {
      return typography.fontWeightBold;
    },
    fontSize: 14,
  },

  caption: {
    color: colors.grey[600],
    fontSize: 12,
  },

  overline: {
    get fontWeight() {
      return typography.fontWeightBold;
    },
  },
};

export default typography;
