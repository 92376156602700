import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Close as CloseIcon } from '@mui/icons-material';
import type { DialogProps, Theme } from '@mui/material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';

import { TestIDs } from 'src/testIDs';
import type { QrScannerProps } from 'src/components/QrScanner/QrScanner';
import QrScanner from 'src/components/QrScanner/QrScanner';

import useStyles from './QrScannerDialog.styles';

interface QrScannerDialogProps
  extends Omit<QrScannerProps, 'style'>,
    Omit<DialogProps, 'onError' | 'onLoad'> {
  className?: string;
}

const QrScannerDialog: FC<QrScannerDialogProps> = ({
  className,
  dutId,
  inspectionId,
  matchUuid,
  onError,
  onScan,
  ...props
}) => {
  const { t } = useTranslation();
  const theme = useTheme<Theme>();
  const fullScreenDialog = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  const classes = useStyles();

  const qrScannerProps: QrScannerProps = {
    dutId,
    inspectionId,
    matchUuid,
    onError,
    onScan,
  };

  return (
    <Dialog
      className={clsx(classes.root, className)}
      classes={{ paper: classes.MuiDialog_paper }}
      data-test-id={TestIDs.components.qrScannerDialog.wrapper}
      fullScreen={fullScreenDialog}
      {...props}
    >
      <DialogTitle>
        {t('QrScannerDialog.scanQrCode')}

        {props.onClose && (
          <IconButton
            onClick={(event) => props.onClose!(event, 'backdropClick')}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent>
        <Box className={classes.qrScannerWrapper}>
          <QrScanner {...qrScannerProps} />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          autoFocus
          color="primary"
          onClick={(event) => props.onClose!(event, 'backdropClick')}
        >
          {t('General.abort')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QrScannerDialog;
