import type { Theme } from '@mui/material';
import { colors } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},

  webCamWrapper: {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',

    '& > *': {
      maxWidth: '100%',
    },
  },

  captureButtonContainer: {
    backgroundColor: colors.common.white,
    borderRadius: '50%',
    bottom: theme.spacing(2),
    position: 'absolute',
  },

  MuiDialog_paper: {
    [theme.breakpoints.up('sm')]: {
      width: '60vw',
    },
  },
}));

export default useStyles;
